import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Moment from "moment";

import { stateMappings } from "../redux/stateMappings";
import { weerdCreate } from "../redux/weerd/thunks";
import { setWeerdyModal } from "../redux/modal/thunks";
import { setToast } from "../lib/toast";
import StripePyamentModal from "../components/StripePyamentModal";
import { APIRequest } from "../lib/apiRequest";
import authHeader from "../Utilities/auth-header";

class Plans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      isCreate: false,
      categorySelect: false,
      category: "",
      show: false,
      categories: [
        "General",
        "Politics",
        "Sports",
        "Technology",
        "Fashion",
        "Gaming",
        "Food",
        "Travel",
        "Art",
        "Education",
        "Science",
        "Comedy",
        "Entertainment",
        "Movies",
        "Gossip",
        "Finance",
      ],
      plan_expires: "",
    };
  }

  componentDidMount() {
    APIRequest.request({
      method: "GET",
      headers: authHeader(),
      url: `/get-expiration/${this.props.currentUser._id}`,
    }).then((result) => {
      this.setState({ plan_expires: result.data.plan_expires });
    });

    let search = window.location.search;
    var params = new URLSearchParams(search);
    var open_popup = params.get("open_popup");
    if (open_popup == true || open_popup == "true") {
      this.handleShow();
    }
  }

  createWrapper = () => {
    this.setState({
      isCreate: !this.state.isCreate,
    });
  };

  categoryDropBox = (category) => {
    this.setState({
      categorySelect: !this.state.categorySelect,
    });

    if (category) {
      this.setState({
        category,
      });
    }
  };

  onChange = ({ target: { name, value } }) =>
    this.setState((state) => ({
      ...state,
      [name]: value,
    }));

  handleSubmit = async (e) => {
    e.preventDefault();

    const { title, category } = this.state;
    const { currentUser, create, setModal } = this.props;

    if (!title || !category) {
      setToast("warning", "Please input title and category");
      return;
    }

    try {
      await create({
        title,
        category,
        author: currentUser._id,
      });
    } catch (error) {
      return;
    }

    setModal("createConfirmModalOpen");
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  render() {
    const {
      title,
      isCreate,
      categorySelect,
      categories,
      category,
      show,
      plan_expires,
    } = this.state;
    //const { currentUser } = this.props;
    const date = new Date(plan_expires);
    const elapsed = date.getTime(); // Elapsed time in MS
    const current_date = new Date();
    const current_elapsed = current_date.getTime(); // Elapsed time in MS
    var dt = plan_expires;

    return (
      <Fragment>
        <section>
          <div className="container">
            <div className="row ">
              <div className="col-lg-12 text-center">
                {!elapsed || current_elapsed > elapsed ? (
                  <h2 className="fs-shrinkhand">Our Pricing Plans</h2>
                ) : (
                  <h2 className="fs-shrinkhand">
                    You have already subscrided!
                  </h2>
                )}
                {!elapsed || current_elapsed > elapsed ? (
                  ""
                ) : (
                  <p>
                    Your plan will expire on: {Moment(dt).format("MMMM Do Y")}
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
        <div className="border-bottom-red"></div>

        <section className="bg-img recommendation">
          <div className="container">
            <div className="row pricing">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="price-details position-relative">
                  <img
                    className="xleft-img"
                    src="/assets/images/x.png"
                    alt=""
                  />
                  <h4 className="fw-bold">Free Users Can:</h4>
                  <ul>
                    <li>
                      <i className="fas fa-check color-green"></i> Create a
                      dating profile
                    </li>
                    <li>
                      <i className="fas fa-check color-green"></i> Upload 5
                      Photos
                    </li>
                    <li>
                      <i className="fas fa-check color-green"></i> Send and
                      receive direct messages
                    </li>
                    <li>
                      <i className="fas fa-check color-green"></i> Like other
                      user's photos
                    </li>
                  </ul>

                  <img className="c-img" src="/assets/images/c.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 sm:mt-16">
                <div className="price-details">
                  <img
                    className="xright-img"
                    src="/assets/images/x.png"
                    alt=""
                  />
                  <img
                    className="badge-img"
                    src="/assets/images/badge.png"
                    alt=""
                  />

                  <img
                    className="rectangle-img"
                    src="/assets/images/rectangle.png"
                    alt=""
                  />
                  <h4 className="fw-bold">Exclusive Users Can:</h4>

                  <ul className="unstyled centered">
                    <li>
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-2"
                        type="checkbox"
                        value="value2"
                        checked
                      />
                      <label for="styled-checkbox-2">
                        Create a dating profile
                      </label>
                    </li>

                    <li>
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-2"
                        type="checkbox"
                        value="value2"
                        checked
                      />
                      <label for="styled-checkbox-2">Upload 10 photos</label>
                    </li>

                    <li>
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-4"
                        type="checkbox"
                        value="value2"
                        checked
                      />
                      <label for="styled-checkbox-4">
                        Send and receive direct messages 
                      </label>
                    </li>

                    <li>
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-5"
                        type="checkbox"
                        value="value2"
                        checked
                      />
                      <label for="styled-checkbox-5">
                        Like other user's photos
                      </label>
                    </li>

                    <li>
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-6"
                        type="checkbox"
                        value="value2"
                        checked
                      />
                      <label for="styled-checkbox-6">
                        Save five other user's profile pages for later
                      </label>
                    </li>

                    <li>
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-7"
                        type="checkbox"
                        value="value2"
                        checked
                      />
                      <label for="styled-checkbox-7">
                        Upload one 30 second video of themselves
                      </label>
                    </li>

                    <li>
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-9"
                        type="checkbox"
                        value="value2"
                        checked
                      />
                      <label for="styled-checkbox-9">
                        See if messages have been read or not
                      </label>
                    </li>

                    <li>
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-10"
                        type="checkbox"
                        value="value2"
                        checked
                      />
                      <label for="styled-checkbox-10">
                        See who viewed their pages
                      </label>
                    </li>

                    <li>
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-11"
                        type="checkbox"
                        value="value2"
                        checked
                      />
                      <label for="styled-checkbox-11">
                        Appear at the top of user searches
                      </label>
                    </li>
                  </ul>
                  {!elapsed || current_elapsed > elapsed ? (
                    <button
                      className="button orange w-100 mt-4"
                      onClick={this.handleShow}
                    >
                      Select
                    </button>
                  ) : (
                    ""
                  )}
                  <img className="m-img" src="/assets/images/m.png" alt="" />
                  <img
                    className="circle-img"
                    src="/assets/images/circle.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <StripePyamentModal show={show} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    currentUser: sm.user,
  };
};

const mapActionsToProps = {
  create: weerdCreate,
  setModal: setWeerdyModal,
};

export default connect(mapStateToProps, mapActionsToProps)(Plans);
