import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import LoginModal from './LoginModal';
import RegisterModal from './RegisterModal';
import TermsModal from './TermsModal';
import PremiumModal from './PremiumModal';
import CreateConfirmModal from './CreateConfirmModal';
import UserNameEditModal from './UserNameEditModal';
import PasswordChangeModal from './PasswordChangeModal';
import WeerdPremiumModal from './WeerdPremiumModal';
import PaymentModal from './PaymentModal';
import { signOut } from '../redux/auth/thunks';
import { setWeerdyModal } from '../redux/modal/thunks';
import { stateMappings } from '../redux/stateMappings';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileMenuOpen: false,
      notifications: false,
    };
  }

  openModal = (event, name = "signInModalOpen") => {
    this.props.setWeerdyModal(event.target.name || name);
  }

  showMobileMenu = () => {
    this.setState({
      isMobileMenuOpen: !this.state.isMobileMenuOpen
    });
  }

  showNotifications = () => {
    this.setState({
      notifications: !this.state.notifications
    });
  }

  render() {
    const { isMobileMenuOpen, notifications } = this.state;
    const {
      isAuthenticated,
      user,
      children,
      signOut,
      signInModalOpen,
      signUpModalOpen,
      termsModalOpen,
      premiumModalOpen,
      createConfirmModalOpen,
      usernameEditModalOpen,
      passwordChangeModalOpen,
      weerdPremiumModalOpen,
      paymentModalOpen,
    } = this.props;

    return (
      <DivContainer>
        <header className={`${isMobileMenuOpen ? "open" : ""}`}>
          <div className="container-fluid bgcolor-black py-4">
              <div className="container top-navigation">
                <div className="brand">
                  <a href="/"><img src="/assets/images/logo.svg" alt="" /></a>
                </div>
                <div className="navbar-btns">
                  <ul>
                  <li>
                    <a href="/notification">
                      <i className="fa fa-bell"></i>
                    </a>
                  </li>
				  {/*<li><a href="#"><i className="fab fa-facebook-f"></i></a> <a href="#"><i className="fab fa-instagram"></i></a></li>*/}
                    <li>
					{/*<a className="button red md" href="#">Download App</a>*/}
                      {!isAuthenticated && <a className="button ghost md" href="/register">Sign Up</a>}
                      {isAuthenticated && <a href={"https://lovacane.com/profile"} className="logged-user">{user?.username}</a>}
                    </li>
                    <li>
                      {!isAuthenticated && <a href="/login">Log In</a>}
                      {isAuthenticated && <span className="logout" onClick={() => signOut()}>Log Out</span>}
                    </li>
                    <li className="hidden md:show"> <img className="cursor toggle-btn" src="/assets/images/toggle-btn.svg" onClick={this.showMobileMenu} alt="" /></li>
                  </ul>
                </div>
              </div>
          </div>
          <div className="bgcolor-red main-menu p-3 md-hidden">
          <div className="container">
            <div className="col-lg-12 my-auto">
              <nav>
                  <ul className="navigation fw-ebold color-black">
                    <li><a href="/">Home</a></li>
                    <li><a href="/profile">Profile</a></li>
                    <li><a href="/chat">Messages</a></li>
                    <li><a href="/filter-members">Discover Lovacane</a></li>
                    <li><a href="/view-profile">Secret Admirers</a></li>
                    <li><a href="/plans">Go Exclusive</a></li>
                    <li><a href="/settings">Settings</a></li>
                  </ul>
              </nav>
            </div>
          </div>
          </div>
          <div id="lgMenu" className={`sidebar-wrapper${isMobileMenuOpen ? " enter" : ""}`}>
            <div className="content">
              <div className="row p-4 bgcolor-black">
                <div className="col my-auto p-0">
                  <div className="brand">
                    <a href="index.html"><img src="/assets/images/logo.svg" alt="" /></a>
                  </div>
                </div>
                <div className="col text-right my-auto p-0">
                  <img style={{ width: 24 }} className="exit" src="/assets/images/cross-icon.svg" onClick={this.showMobileMenu} alt="cross-icon" />
                </div>
              </div>
              <div className="mobile-nav p-4 bgcolor-black-1">
                <ul className="fw-ebold color-white">
                    <li><a href="/">Home</a></li>
                    <li><a href="/profile">Profile</a></li>
                    <li><a href="/chat">Message</a></li>
                    <li><a href="/filter-members">Discover Lovacane</a></li>
                    <li><a href="/view-profile">Secret Admirers</a></li>
                    <li><a href="/plans">Go Exclusive</a></li>
                    <li><a href="/settings">Settings</a></li>
                  </ul>
                  <div className="navbar-btns">
                  <ul className="v-middle">
                    <li>
                      {!isAuthenticated && <a className="button ghost md" href="/register">Sign Up</a>}
                      {isAuthenticated && <strong className="logged-user">{user?.username}</strong>}
                    </li>
                    <li>
                      {!isAuthenticated && <a href="/login">Log In</a>}
                      {isAuthenticated && <span className="logout" onClick={() => signOut()}>Log Out</span>}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* <div className="bgcolor-gray p-3 md-hidden position-sticky">
          <div className="container">
            <div className="col-lg-12 my-auto">
              <nav>
                <ul className="navigation fw-ebold color-black">
                  {true && <li><a href="/create">Create a Weerd</a></li>}
                  {true && <li><a href="/categories">Weerdy Categories</a></li>}
                  {true && <li><a href="/profile">Profile</a></li>}
                  {true && <li><a href="/day">Weerds of the Day</a></li>}
                  {true && <li><a href="#" onClick={this.openModal} name="premiumModalOpen">Premium</a> </li>}
                  <li><a href="/explained">Weerdy Explained</a> </li>
                  <li><a href="/donate">Donate</a></li>
                  <li><a href="#" onClick={this.openModal} name="termsModalOpen">Terms</a></li>
                  {true &&
                    <Fragment>
                      <li><a href="#" onClick={this.openModal} name="signInModalOpen">Log In</a> / <a href="#" onClick={this.openModal} name="signUpModalOpen">Sign Up</a></li>
                    </Fragment>
                  }
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <LoginModal open={this.openModal} isOpen={signInModalOpen} customStyles={customStyles} />
        <RegisterModal open={this.openModal} isOpen={signUpModalOpen} customStyles={customStyles} />
        <TermsModal open={this.openModal} isOpen={termsModalOpen} customStyles={customStyles} />
        <PremiumModal open={this.openModal} isOpen={premiumModalOpen} customStyles={customStyles} />
        <CreateConfirmModal open={this.openModal} isOpen={createConfirmModalOpen} customStyles={customStyles} />
        <UserNameEditModal open={this.openModal} isOpen={usernameEditModalOpen} customStyles={customStyles} />
        <PasswordChangeModal open={this.openModal} isOpen={passwordChangeModalOpen} customStyles={customStyles} />
        <WeerdPremiumModal open={this.openModal} isOpen={weerdPremiumModalOpen} customStyles={customStyles} />
        <PaymentModal open={this.openModal} isOpen={paymentModalOpen} customStyles={customStyles} /> */}
        {children}
        <footer className="bgcolor-black d-none">
          <div className="container">
            <div className="footer-content">
			{/*<div>
                <h5>Top Blogs</h5>
                <ul>
                  <li><a href="#">20 Signs Tell If Someone Like You</a></li>
                  <li><a href="#">10 Secrets to Make Love Last</a></li>
                  <li><a href="#">Most Popular Body Type</a></li>
                  <li><a href="#">The  Best Free Dating Apps</a></li>
                  <li><a href="#">Loving Couples habbits</a></li>
                  <li><a href="#">Most Attractive Stories</a></li>
                </ul>
              </div>

              <div>
                <h5>Community</h5>
                <ul>
                  <li><a href="#">Lovacane Community Guidelines</a></li>
                  <li><a href="#">Lovacane Magazine</a></li>
                  <li><a href="#">Love Stories</a></li>
                  <li><a href="#">FAQs</a></li>
                </ul>
              </div>

              <div>
                <h5>Stay safe</h5>
                <ul>
                  <li><a href="#">Safety</a></li>
                  <li><a href="/terms-and-rules">Terms</a></li>
                  <li><a href="#">Privacy</a></li>

                </ul>
              </div>

              <div>
                <h5>Company</h5>
                <ul>
                  <li><a href="/contact-us">Contact</a></li>
                  <li><a href="#">Lovacane Promo Code</a></li>
                  <li><a href="#">Newsroom</a></li>

                </ul>
              </div>

              <div>
                <h5>Follow us</h5>
                <ul className="social-media">
                  <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                  <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                  <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>
                  <li><a href="#"><i className="fab fa-instagram"></i></a></li>

                </ul>

                <h5 className="mt-4">Download Lovacane App</h5>
                <img src="/assets/images/qr-code.jpg" alt="" />
                <ul>
                  <li>App Store & Google Play</li>
                </ul>
			</div>*/}

            </div>
          </div>
        </footer>
      </DivContainer>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);
  return {
    user: sm.user,
    isAuthenticated: sm.isAuthenticated,
    signInModalOpen: sm.signInModalOpen,
    signUpModalOpen: sm.signUpModalOpen,
    termsModalOpen: sm.termsModalOpen,
    premiumModalOpen: sm.premiumModalOpen,
    createConfirmModalOpen: sm.createConfirmModalOpen,
    usernameEditModalOpen: sm.usernameEditModalOpen,
    passwordChangeModalOpen: sm.passwordChangeModalOpen,
    weerdPremiumModalOpen: sm.weerdPremiumModalOpen,
    paymentModalOpen: sm.paymentModalOpen,
  };
};

const mapActionsToProps = {
  signOut,
  setWeerdyModal,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(Layout);

const DivContainer = styled.div`
  .logged-user,
  .logout {
    color: #ffffff;
  }

  .logout {
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
`
