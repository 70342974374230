import React, { useEffect, Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import Slider from 'rc-slider';
import { stateMappings } from '../redux/stateMappings';
import { getLovacaneUsers } from '../redux/admin/thunks';
import { setToast } from '../lib/toast';
import environment from '../environments/environment.dev';
import { setWeerdyModal } from '../redux/modal/thunks';
import { APIRequest } from "../lib/apiRequest";
import 'rc-slider/assets/index.css';
import '../slider_bar_color.css';
import authHeader from "../Utilities/auth-header";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const TooltipSlider = createSliderWithTooltip(Slider)
const Range = createSliderWithTooltip(Slider.Range);

const myComponentStyle = {
  cursor: 'pointer',
}

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      identifiedGender: '',
      physicalGender: '',
      letter: '',
	  state: '',
	  postcode: '',
    minAge: '',
    maxAge: '',
    distance: '',
    };

  }

  componentDidMount(prevProps) {
    this.props.getUsers();
    

  }

  componentDidUpdate(prevProps, prevStates) {
    if (!_.isEqual(prevProps.settings, this.props.settings)) {
      this.setState({
        ...this.state,
        ...this.props.settings
      });
    }
   
  }

  onChange = ({ target: { name, value } }) =>
    this.setState(state => ({
      ...state,
      [name]: value,
    }));


    onHandleDistanceChange = (value, isUpdate = false) => {
      this.setState({
        ...this.state,
        distance: value
      }, () => {
        if (!isUpdate) {
          return;
        }
        //this.props.updateUser(this.state);
      });
    }
  
    onHandleAgeChange = (value, isUpdate = false) => {
      this.setState({
        ...this.state,
        minAge: value[0],
        maxAge: value[1],
      }, () => {
        if (!isUpdate) {
          return;
        }
        //this.props.updateUser(this.state);
      });
    }
  
  handleSubmit = async (e) => {
    e.preventDefault();

    if(this.state.physicalGender == 'male' && this.state.identifiedGender == 'male' && this.state.letter == 'L'){
      alert("Only [PG] females may select the Lesbian option");
      return false;
    }
 
 

    const {currentUser } = this.props;

    
    APIRequest.request({
      method: "POST",
      headers: authHeader(),
      url: `/filter-users`,
      data: {
        user_id: currentUser._id,
        distance:this.state.distance,
        latitude:currentUser.latitude,
        longitude:currentUser.longitude,
        state:this.state.state,
        gender:this.state.identifiedGender,
        physicalGender:this.state.physicalGender,
        minAge:this.state.minAge,
        maxAge:this.state.maxAge
      },
    }).then((result) => {

      var usrs = result.data;
      var users1 = usrs;
      var current_date = new Date();
      var current_elapsed = current_date.getTime(); 

      for(let i = 0; i < usrs.length; i++) {

        if(usrs[i].plan_expires != undefined){
          var date = new Date(usrs[i].plan_expires);
          var elapsed = date.getTime(); 

          if(current_elapsed > elapsed){
            usrs[i].active_plan = 1;
          }else {
            usrs[i].active_plan = 0;
          }

        } else {
          usrs[i].active_plan = 0;
        }
         
      }  

      //usrs.sort((a, b) => parseFloat(b.active_plan) - parseFloat(a.active_plan));

      this.setState({ users: usrs });

      const {users } = usrs;
      

    });

    
    

  };




  render() {

  const {
    identifiedGender,
    physicalGender,
    gender,
    letter,
  state,
  } = this.state;


    return (
      <Fragment>
        <section>
          <div className="container">
            <div className="row ">
              <div className="col-lg-12 text-center">
                <h2 className="fs-shrinkhand">Find Your Match</h2>
              </div>
            </div>
          </div>
        </section>
        <div className="border-bottom-red"></div>
          <section className="p-0">
            <div className="container-fluid p-0 full-width-container">
                <div className="row text-center">
                  <div className="col-lg-5 col-md-5 col-sm-12">
                    <form onSubmit={this.handleSubmit} onload={this.handleSubmit} className="bgcolor-lt-gray radius-md p-8 mt-8">
                      
                      <div className="filters">
                              
                        <div className="list">
                            <p>Match Distance</p>
                            <TooltipSlider
                              max={5000}
                              value={this.state?.distance || 0}
                              tipFormatter={value => `${value} mile(s)`}
                              onAfterChange={(value) => this.onHandleDistanceChange(value, true)}
                              onChange={(value) => this.onHandleDistanceChange(value, false)}
                            />
                          </div>
                          <div className="list">
                            <p>Match Age</p>
                            <Range
                              min={18}
                              max={70}
                              value={[this.state?.minAge || 18, this.state?.maxAge || 70]}
                              tipFormatter={value => `${value}`}
                              onAfterChange={(value) => this.onHandleAgeChange(value, true)}
                              onChange={(value) => this.onHandleAgeChange(value, false)}
                            />
                          </div>
						  
                          <div className="list">
                            <p>My Physical-Gender</p>
                            <select className="form-control" value={physicalGender} name="physicalGender" onChange={this.onChange}>
                            <option value="">---Select---</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </div>
                          <div className="list">
                            <p>I Identify As</p>
                            <select className="form-control" value={identifiedGender} name="identifiedGender" onChange={this.onChange}>
                            <option value="">---Select---</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </div>
                          <div className="list">
                            <p>My LGBTQ Letter</p>
                            <select className="form-control" value={letter} name="letter" onChange={this.onChange}>
                            <option value="">---Select---</option>
                              <option value="L">(L)esbian</option>
                          <option value="G">(G)ay</option>
                          <option value="B">(B)isexual</option>
                          <option value="TW">(T)ransexual Woman</option>
                          <option value="TM">(T)ransexual Man</option>
						  <option value="Q">(Q)ueer</option>
                            </select>
                          </div>
						{/*
                        <div className="list">
                          <p>Heght</p>
                          <p><strong>Min:5'3 - Max:6'</strong></p>
                        </div>
                        <div className="list">
                          <p>Online</p>
                          <label className="switch-wrap">
                            <input type="checkbox" />
                            <div className="switch"></div>
                          </label>
                        </div>
                        <div className="list">
                          <p>Verified</p>
                          <label className="switch-wrap">
                            <input type="checkbox" />
                            <div className="switch"></div>
                          </label>
                        </div>
						*/}
                        <button type="submit" className="button red md w-100 mt-8">Show Me</button>
                      </div>
                    </form>
                  </div>
                  <div className="mulitcolor-bg bg-img col-lg-7 col-md-7 col-sm-12 sm:mt-8">
                    <ul className="membersList">
                      {this.state.users.length === 0 && <h2 className="text-white fs-shrinkhand white"><strong>No Users</strong></h2>}
                      {this.state.users.map((user, index) => {

                     var age = new Date().getFullYear() - parseInt(user?.birthday?.split('/')[2] || '0');

                    if(age >= this.state.minAge && age <= this.state.maxAge){

              
return (
                       <>
                        <li key={`filtered-members-${index}`} className="member">
                          <div className="member-dp">
						  {/*<img src="/assets/images/dp.jpg" alt="" />*/}
						  {(user.picture) ? 
						  <img className="main-profile w-100 d-block" src={environment.img_url+"userImages/gallery/"+user.picture} alt="profile" /> : 
						  <img className="main-profile w-100 d-block" src="./assets/images/img.png" alt="profile" /> }
                          </div>
                          <div className="member-details">
                            <div className="title">
                              <a href={"/user-profile/"+user._id}>
                                <p>{`${user?.username} `}{/*<i className="fas fa-circle color-green online"></i>*/}</p>
                {user?.disableAge ? '' : 
                              <p>{`${new Date().getFullYear() - parseInt(user?.birthday?.split('/')[2] || '0')} yo`}</p>}
                              </a>
                            </div>
                            <div className="description">


                              <p className="fs-14 mt-2 text-left">{user.bio ? `${user.bio}` : 'No bio found!'}</p>
							  { user?.disableDistance ? <p className="mt-2 text-right">{`${user?.state}`}</p> : 
                              <p className="mt-2 text-right">{`${user?.state}, ${user?.userDistance.toFixed(2)} Mile(s)`}</p> }
                            </div>
                          </div>
                      </li>
                      </>  
);
           }   } )}
                    </ul>
                  </div>
                </div>
            </div>
          </section>
      </Fragment>
    );
  }
}

const mapStateToProps = ( state, ownProps ) => {
  const sm = stateMappings(state);
  return {
    users: sm.users,
    currentUser: sm.user,
	settings: sm.settings,
  };
};

const mapActionsToProps = {
  getUsers: getLovacaneUsers
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(Filter);

