import {
  getWeerds,
  postWeerd,
  putWeerd,
  deleteWeerd,
} from './apiCalls';
import {
  setWeerds,
  addWeerd,
  editWeerd,
  removeWeerd,
  setLoadingStatus,
  setErrorMessage,
} from './actions';
import { setToast } from '../../lib/toast';


const setStatus = (status, message) => (
  dispatch
) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const weerdGets = () => async (
  dispatch
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data: weerds } = await getWeerds();

    dispatch(setWeerds(weerds));
    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.message));
  }
};

export const weerdCreate = (candidate) => async (
  dispatch
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data: weerd } = await postWeerd(candidate);

    dispatch(addWeerd(weerd));
    dispatch(setStatus(false, ''));
    setToast('success', 'Weerd has been posted successfully.');
  } catch (error) {
    setToast('warning', 'This request has been blocked.');
    dispatch(setStatus(false, error.message));
  }
};

export const weerdEdit = (weerd, isNotification = true) => async (
  dispatch
) => {
  const { type, ...candidate } = weerd;
  try {
    dispatch(editWeerd(weerd));
    await putWeerd(weerd._id, !type ? candidate : { [type]: weerd[type] });
    if (isNotification) {
      setToast('success', 'Weerd has been edited successfully.');
    }
  } catch (error) {
  }
};

export const weerdRemove = ({ _id }) => async (
  dispatch
) => {
  console.log('id', _id);
  dispatch(setStatus(true, ''));

  try {
    await deleteWeerd(_id);

    dispatch(removeWeerd(_id));
    dispatch(setStatus(false, ''));
    setToast('success', 'Weerd has been removed successfully.');
  } catch (error) {
    dispatch(setStatus(false, error.message));
  }
};

export const setLoading = setStatus;
