//This file holds the API calls that hit the /user route for DRY purposes
import { APIRequest } from '../../lib/apiRequest';

export const authenticate = async (credentials) =>
  APIRequest.request({
    method: 'POST',
    url: `/login`,
    data: {
      ...credentials
    }
  });

export const register = async (user) =>
  APIRequest.request({
    method: 'POST',
    url: `/user`,
    data: {
      ...user,
    }
  });

export const getUser = async (userId) =>
  APIRequest.request({
    method: 'GET',
    url: `/user/${userId}`,
  });

export const putUser = async (user) =>
  APIRequest.request({
    method: 'PUT',
    url: `/user/${user._id}`,
    data: {
      ...user,
    }
  });

export const deactivateUser = async (user) =>
  APIRequest.request({
    method: 'DELETE',
    url: `/user/${user._id}`
  });
