import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';

import { stateMappings } from '../../redux/stateMappings';
import ChatBox from './ChatBox';
import Conversations from './Conversations';
import Users from './Users';

const useStyles = makeStyles(theme => ({
    paper: {
        minHeight: 'calc(100vh - 140px)',
        borderRadius: 0,
    },
    sidebar: {
        zIndex: 8,
    },
    subheader: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    globe: {
        backgroundColor: theme.palette.primary.dark,
    },
    subheaderText: {
        color: theme.palette.primary.dark,
    },
}));

const Chat = (props) => {
    const [scope, setScope] = useState('Global Chat');
    const [tab, setTab] = useState(0);
    const [user, setUser] = useState(null);
    const classes = useStyles();

    const handleChange = (e, newVal) => {
        setTab(newVal);
    };

    return (
        // <React.Fragment>
        //     <Grid container>
        //         <Grid item md={4} className={classes.sidebar}>
        //             <Paper className={classes.paper} square elevation={5}>
        //                 <Paper square>
        //                     <Tabs
        //                         onChange={handleChange}
        //                         variant="fullWidth"
        //                         value={tab}
        //                         indicatorColor="primary"
        //                         textColor="primary"
        //                     >
        //                         <Tab label="Chats" />
        //                         <Tab label="Users" />
        //                     </Tabs>
        //                 </Paper>
        //                 {tab === 0 && (
        //                     <Conversations
        //                       setUser={setUser}
        //                       setScope={setScope}
        //                       currentUser={props.currentUser}
        //                     />
        //                 )}
        //                 {tab === 1 && (
        //                     <Users
        //                       setUser={setUser}
        //                       setScope={setScope}
        //                       currentUser={props.currentUser}
        //                     />
        //                 )}
        //             </Paper>
        //         </Grid>
        //         <Grid item md={8}>
        //           <ChatBox
        //             scope={scope}
        //             user={user}
        //             currentUser={props.currentUser}
        //           />
        //         </Grid>
        //     </Grid>
        // </React.Fragment>
        <section className="mulitcolor-bg bg-img profile">
          <div className="container chat">
              <div className="row ">
                <div className="col-lg-12 bgcolor-red">
                  <h5>Messages</h5>
                </div>
              </div>
              <div className="row bgcolor-white">
                <div className="col-lg-4 col-md-4">
                  {/* <div className="search chat-search mt-8">
                    <form>
                      <input className="form-control people-input" type="text" placeholder="Search people" />
                      <i className="fas fa-search"></i>
                    </form>
                  </div> */}
                  <Tabs
                    onChange={handleChange}
                    variant="fullWidth"
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label="Connections" />
                    {/*<Tab label="Connections" />*/}
                  </Tabs>
                  {tab === 0 && (
                    <Conversations
                      scope={scope}
                      setUser={setUser}
                      setScope={setScope}
                      currentUser={props.currentUser}
                    />
                  )}
                  {/*tab === 1 && (
                    <Users
                      scope={scope}
                      setUser={setUser}
                      setScope={setScope}
                      currentUser={props.currentUser}
                    />
                  )*/}
                </div>
                <div className="col-lg-8 col-md-8 mt-8">
                  <ChatBox
                    scope={scope}
                    user={user}
                    currentUser={props.currentUser}
                  />
                </div>
                {/* <div className="col-lg-3 col-md-12 mb-8">
                  <div className="chating-person-info text-center">

                        <div className="person-dp mt-8 mb-8">
                          <img src="/assets/images/tom.jpg" alt="profile" />
                          <h5 className="fw-bold name">Tom</h5>
                        </div>
                        <div className="user-actions">
                          <a href="#"><i className="fas fa-phone-alt"></i></a>
                          <a href="#"><i className="fas fa-video color-green"></i></a>
                        </div>
                  </div>
                  <div className="chat-attachments">
                      <div className="mt-8 mb-4 d-flex align-items-center justify-content-between ">
                        <h6 className="fw-bold text-center p-4 bgcolor-red w-100 color-white ">About <span className="name">Tom</span></h6>
                      </div>
                      <div className="chat-files">
                        <p className="fs-11">Shared Photos</p>

                        <div className="row mt-2">
                          <div className="col-lg-4 col-md-4 col-sm-4">
                            <img className="w-100" src="/assets/images/i1.jpg" alt="" />
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4">
                            <img className="w-100" src="/assets/images/i2.jpg" alt="" />
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4">
                            <img className="w-100" src="/assets/images/i3.jpg" alt="" />
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4">
                            <img className="w-100" src="/assets/images/i4.jpg" alt="" />
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4">
                            <img className="w-100" src="/assets/images/i5.jpg" alt="" />
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4">
                            <img className="w-100" src="/assets/images/i1.jpg" alt="" />
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4">
                            <img className="w-100" src="/assets/images/i3.jpg" alt="" />
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4">
                            <img className="w-100" src="/assets/images/i2.jpg" alt="" />
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4">
                            <img className="w-100" src="/assets/images/i1.jpg" alt="" />
                          </div>
                        </div>

                      </div>
                  </div>
                </div> */}
            </div>
          </div>
      </section>
    );
};

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);
  return {
    currentUser: sm.user,
  };
};

export default connect(
  mapStateToProps,
  undefined,
)(Chat);
