import React, { Component, Fragment } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { connect } from 'react-redux';

import PaymentModal from './PaymentModal';
import { stateMappings } from '../redux/stateMappings';
import { setWeerdyModal } from '../redux/modal/thunks';

Modal.setAppElement('#root');

class PremiumModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      plan: 'month'
    };
  }

  setSubscriptionPlan = (plan) => {
    this.setState({
      plan: plan,
    });
  }

  render() {
    const {
      open,
      isOpen,
      paymentModalOpen,
      customStyles,
      setModal,
    } = this.props;
    const { plan } = this.state;

    return (
      <Fragment>
        <Modal
          isOpen={isOpen}
          onRequestClose={(e) => open(e, "premiumModalOpen")}
          closeTimeoutMS={500}
          style={customStyles}
          className="modal fade show"
        >
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content onboardingModal">
              <div className="row">
                <div className="col-lg-12 mb-8">
                  <h5 className="fw-bold mb-2 text-center">Select Your Subscription Plan</h5>
                  <p className="fs-poppins mb-4">Purchase a monthly or yearly premium account that allows you to enjoy all of Weerdy's perks without restrictions.</p>
                    <ul className="lh-1-6 fs-14 text-left mb-3">
                      <li>-Monthly Premium Account: <span className="color-green">$19.99</span></li>
                      <li>-Yearly Premium Account: <span className="color-green">$199.99 (saves you 16.63% over the monthly option)</span></li>
                    </ul>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-auto text-center">
                  <img className="ee-image" src="/assets/images/ee-image.png" alt="premium-ee " />
                </div>
                <DivContainer className="col-lg-6 col-md-6 col-sm-12">
                  <form className="subscription-plan">
                    <div className={`relative${plan === 'month' ? ' checked' : ''}`}>
                      <input type="radio" name="payment_plan" id="monthly"  value="XX/Monthly" />
                      <label onClick={() => this.setSubscriptionPlan('month')} className="button red w-100 radius border-black" htmlFor="monthly"><span>$19.99</span>/Monthly</label>
                      <i className="fa fa-check"></i>
                    </div>
                    <div className={`relative${plan === 'year' ? ' checked' : ''}`}>
                      <input type="radio" name="payment_plan" id="yearly" value="XX/Yearly" />
                      <label onClick={() => this.setSubscriptionPlan('year')} className="button red w-100 radius border-black" htmlFor="yearly"><span>$199.99</span>/Yearly</label>
                        <i className="fa fa-check"></i>
                    </div>
                    <button onClick={() => setModal('paymentModalOpen')} type="button" className="button w-100 radius border-black mt-4 ">Purchase</button>
                  </form>
                </DivContainer>
                <p className="fs-poppins fs-14 mt-4 text-center">Don't want a premium account? No problem. Get the features you want for one low price, and when you are ready, <a className="fw-bold color-green" data-toggle="modal" data-dismiss="modal" data-target="#PurchaseablePlanModal" href="#">join us!</a></p>
              </div>
            </div>
          </div>
        </Modal>
        <PaymentModal open={open} isOpen={paymentModalOpen} customStyles={customStyles} plan={plan} />
      </Fragment>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    paymentModalOpen: sm.paymentModalOpen,
  };
};

const mapActionsToProps = {
  setModal: setWeerdyModal,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(PremiumModal);

const DivContainer = styled.div`
  .relative {
    margin-top: 1.5rem;
  }
`
