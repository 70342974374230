import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { stateMappings } from "../redux/stateMappings";
import { signOut } from "../redux/auth/thunks";
import { setWeerdyModal } from "../redux/modal/thunks";
import { APIRequest } from "../lib/apiRequest";
import authHeader from "../Utilities/auth-header";
import { setToast } from "../lib/toast";
import environment from "../environments/environment.dev";
import ImageSlider from "../components/ImageSlider";

class ViewProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bio: "",
      interests: "",
      facts: "",
      info: {},
      imgSrc: [],
      imgCollection: [],
      topPicks: [],
      isEdited: 0,
      physicalGender: "",
      identifiedGender: "",
      letter: "",
      images: [],
      viewed: [],
      saved:[],    
      plan_expires:"",
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const interestedGender = user.physicalGender == "male" ? "female" : "male";

    APIRequest.request({
      method: "GET",
      headers: authHeader(),
      url: `/get-profile/${user._id}`,
    }).then((result) => {
      this.setState({ info: result.data });
      this.setState({ bio: result.data.bio });
      this.setState({ interests: result.data.interests });
      this.setState({ facts: result.data.facts });

      this.setState({ physicalGender: result.data.physicalGender });
      this.setState({ identifiedGender: result.data.identifiedGender });
      this.setState({ letter: result.data.letter });
      this.setState({ plan_expires: result.data.plan_expires });
    });


    var date = new Date();
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var maxYear = date.getFullYear() - 1 - user.maxAge;
    var minYear = date.getFullYear() - user.minAge;
    var minYearDate = month + "/" + day + "/" + minYear;
    var maxYearDate = month + "/" + day + "/" + maxYear;

 
    APIRequest.request({
      method: "GET",
      headers: authHeader(),
      url: `/view-profile/${user._id}`,
    }).then((result) => {
      this.setState({ viewed: result["data"] });
    });

    APIRequest.request({
      method: "GET",
      headers: authHeader(),
      url: `/view-saved-profile/${user._id}`,
    }).then((result) => {
      this.setState({ saved: result["data"] });
    });


  }

  Edit = (e) => {
    e.preventDefault();
    this.setState({ isEdited: 1 });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  fileSelectedHandler = (event) => {
    const { images } = this.state;

    var len = event.target.files.length;
    const { settings } = this.props;
    const date = new Date(settings.plan_expires);
    const elapsed = date.getTime(); // Elapsed time in MS
    const current_date = new Date();
    const current_elapsed = current_date.getTime(); // Elapsed time in MS
    if (!elapsed || current_elapsed > elapsed) {
      var total_pic = 5;
    } else {
      var total_pic = 10;
    }

    total_pic = total_pic - images.length;

    if (len <= total_pic) {
      var reader = "";
      this.setState({
        imgSrc: [],
      });
      for (var i = 0; i < len; i++) {
        reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = (e) => {
          this.setState((prevState) => ({
            imgSrc: [...prevState.imgSrc, e.target.result],
          }));
        };
      }
    } else {
      if (total_pic == 0) {
        alert("You have already reached your gallery image limit");
      } else {
        alert("Can not upload more than " + total_pic + " images");
      }
    }
  };

  upload = (event) => {
    const { imgSrc } = this.state;
    const { user } = this.props;

    setToast("success", "Uploading in Progress! Please Wait.");

    APIRequest.request({
      method: "PUT",
      headers: authHeader(),
      url: `/update-images`,
      data: {
        imgCollection: imgSrc,
        user: user._id,
      },
    }).then((result) => {
      setToast("success", "Images are uploaded successfully.");
      window.location.reload(false);
    });
  };

  handleSubmit = (e) => {
    e.preventDefault(e);
    const { bio, interests, facts, physicalGender, identifiedGender, letter } =
      this.state;
    const { user } = this.props;
    this.setState({ isEdited: 0 });
    APIRequest.request({
      method: "PUT",
      headers: authHeader(),
      url: `/update-profile`,
      data: {
        bio: bio,
        interests: interests,
        facts: facts,
        user: user._id,
        physicalGender: physicalGender,
        identifiedGender: identifiedGender,
        letter: letter,
      },
    }).then((result) => {
      setToast("success", "Profile has been updated successfully.");
      window.location.reload(false);
    });
  };

  render() {
    const { signOut, setWeerdyModal } = this.props;
    const { username, email, plan, _id, plan_expires } = this.props.user || {};
    const {
      isEdited,
      bio,
      interests,
      facts,
      info,
      imgCollection,
      topPicks,
      physicalGender,
      identifiedGender,
      letter,
      viewed,
      saved,
    } = this.state;

    
    const { settings } = this.props;
    const date = new Date(settings.plan_expires);
    const elapsed = date.getTime(); // Elapsed time in MS
    const current_date = new Date();
    const current_elapsed = current_date.getTime(); // Elapsed time in MS
    if (!elapsed || current_elapsed > elapsed) {
      var total_pic = 5;
    } else {
      var total_pic = 10;
    }

    //alert(current_elapsed+"  "+elapsed);

    return (
      <Fragment>
               <section className="bg-img profile">
          <div className="container">
            {!elapsed || current_elapsed > elapsed ? (

              <div className="row">
 
                <div className="col-lg-12 text-center">
                  <h2 className="fs-shrinkhand text-white mb-5">
                    This feature is for premium users
                  </h2>
                  <a href="https://lovacane.com/plans">
                    <img src="/assets/images/upgrade.png" alt="" />
                  </a>
                </div>
              </div>
            ) : (
              <>
                <h2 className="fs-shrinkhand text-pink mb-2">Profile Visits</h2>
                <div className="content">
                  {viewed.length > 0 ? (
                    <table
                      className="table m-0"
                      cellPadding={4}
                      cellSpacing={4}
                    >
                      <thead>
                        <tr>
                          <th className="fs-shrinkhand">Name</th>
                          <th className="fs-shrinkhand">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {viewed.map((data, index) => (
                          <tr key={index}>
                            <td>
                              <b>{data.viewId.username}</b>
                            </td>
                            <td>
                              <a href={"/user-profile/" + data.viewId._id}>
                                View Profile
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (

                    <table
                      className="table m-0"
                      cellPadding={4}
                      cellSpacing={4}
                    >

                      <tbody>

                          <tr>
                            <td>
                              <b>No Profile Visits Yet...</b>
                            </td>
                          </tr>
   
                      </tbody>
                    </table>
                  )}
                </div>
                <br></br><br></br><br></br><br></br><br></br>  
                <h2 className="fs-shrinkhand text-pink mb-2">Saved Profiles</h2>
                <div className="content">
                  {saved.length > 0 ? (
                    <table
                      className="table m-0"
                      cellPadding={4}
                      cellSpacing={4}
                    >
                      <thead>
                        <tr>
                          <th className="fs-shrinkhand">Name</th>
                          <th className="fs-shrinkhand">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {saved.map((data, index) => (
                          <tr key={index}>
                            <td>
                              <b>{data.viewId.username}</b>
                            </td>
                            <td>
                              <a href={"/user-profile/" + data.viewId._id}>
                                View Profile
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <table
                    className="table m-0"
                    cellPadding={4}
                    cellSpacing={4}
                  >    
                    <tbody>

                    <tr>
                      <td>
                        <b>No Saved Profiles…</b>
                      </td>
                    </tr>

                </tbody>
              </table>
   
                  )}
                </div>


              </>
            )}
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    user: sm.user,
    settings: sm.settings,
  };
};

const mapActionsToProps = {
  signOut,
  setWeerdyModal,
};

export default connect(mapStateToProps, mapActionsToProps)(ViewProfile);

const SectionContainer = styled.section`
  .plan {
    text-transform: capitalize;

    span {
      cursor: pointer;

      &:hover {
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        color: inherit;
      }
    }
  }
`;
