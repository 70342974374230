import React, { Component } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

Modal.setAppElement('#root');

class PaymentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payment: 'master'
    };
  }

  setPaymentMethod = (method) => {
    this.setState({
      payment: method,
    });
  }

  onChange = ({ target: { name, value } }) =>
    this.setState(state => ({
      ...state,
      payment: value,
    }));

  render() {
    const {
      open,
      isOpen,
      customStyles,
    } = this.props;
    const { payment } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={(e) => open(e, "paymentModalOpen")}
        closeTimeoutMS={500}
        style={customStyles}
        className="modal fade show"
      >
        <div className="modal-dialog modal-md modal-dialog-centered" role="document">
          <div className="modal-content onboardingModal">
            <div className="row">
              <div className="col-lg-12 mb-8 text-center">
                <h5 className="fw-bold">Select Your Payment Method</h5>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12  my-auto text-center sm:hidden">
                <img className="ee-image" src="/assets/images/ee-image.png" alt="image" />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <form className="pay-methods">
                  <div className="relative checked">
                    <input type="radio" name="master" id="master-card"  value="master" onChange={this.onChange} checked={payment === 'master'} />
                    <label className="button white w-100 radius border-black" for="master-card">Master Card</label>
                    <img src="/assets/images/mc.svg" alt="" />
                  </div>
                  <div className="relative">
                  <input type="radio" name="paypal" id="paypal" value="paypal" onChange={this.onChange} checked={payment === 'paypal'} />
                  <label  className="button white w-100 radius border-black" for="paypal">Paypal</label>
                  <img src="/assets/images/pp.svg" alt="" />
                  </div>
                  <div className="relative">
                  <input type="radio" name="visa" id="visa" value="visa" onChange={this.onChange} checked={payment === 'visa'} />
                  <label  className="button white w-100 radius border-black" for="visa">Visa</label>
                  <img src="/assets/images/vi.svg" alt="" />
                  </div>
                  <div className="relative">
                  <input type="radio" name="express" id="american-express" onChange={this.onChange} value="express" checked={payment === 'express'} />
                  <label  className="button white w-100 radius border-black" for="american-express">American Express</label>
                  <img src="/assets/images/ae.svg" alt="" />
                  </div>
                  <button data-toggle="modal" data-target="#ConfirmationModal" data-dismiss="modal" type="button" className="button green w-100 radius border-black mt-4 ">Next</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default PaymentModal;

const DivContainer = styled.div`
  .relative {
    margin-top: 1.5rem;
  }
`
