import React, { useState,useEffect,useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import PayPalBtn from './PayPalBtn';
import { APIRequest } from '../lib/apiRequest';
import authHeader from '../Utilities/auth-header';
import { setToast } from '../lib/toast';
import environment from '../environments/environment.dev';
import { useSelector } from "react-redux";
import { stateMappings } from "../redux/stateMappings";
import Loading from '../components/Loading';
import { duration } from "moment";


const CheckoutForm = () => {

    //alert(this.state.amount);

	const state = useSelector((state) => state);
	const user = stateMappings(state).user;
	const stripe = useStripe();
	const elements = useElements();
	const [loading, setLoading] = useState(false);
	const [card, setCard] = useState({cardnumber: '', expmonth: '', expyear: '', cvc: ''});
	const paypal = useRef();

	let search = window.location.search;
	var params = new URLSearchParams(search);
	
	//let foo = params.get('query');
	//console.log(foo);
    //const [searchParams, setSearchParams] = useParams();

	const plan_type = params.get('plan_type');
	const trigger_click = params.get('open_popup');




	useEffect(() => {
        var params = new URLSearchParams(search);
		var plan_type = params.get('plan_type');
		if(plan_type == '' || plan_type == null || plan_type == 'monthly'){
			var amt = 4.99;
			var dur = 1;
		}else {
			var amt = 49.99;
			var dur = 12;
		}
    //console.log(plan_type+" - "+amt); 

    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "Cool looking table",
                amount: {
                  currency_code: "USD",
                  value: amt,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
		  let now = new Date();
          APIRequest.request({
			method: 'PUT',
			headers: authHeader(),
			url: `/update-subscription`,
			data: {
			  email: user.email,
			  plan: "paid",
			  plan_expires: new Date(now.setMonth(now.getMonth() + dur)),
			  payment_method: 'paypal',
			}
		 }).then((result)=> {
			 if(result) {
				 window.location.reload();
				 setToast("success", "Payment successful!");
			 }
		 });
        },
        onError: (err) => {
          setToast("error", err.message);
        },
      })
      .render(paypal.current);
  }, []);
	
	/*const paypalSubscribe = (data, actions) => {
		return actions.subscription.create({
			'plan_id': "P-1A902155M6531100SMJT2PNA",
		});
	};

	const paypalOnApprove = (data, detail) => {
		let now = new Date();
		APIRequest.request({
			method: 'PUT',
			headers: authHeader(),
			url: `/update-subscription`,
			data: {
			  email: user.email,
			  plan: "paid",
			  plan_expires: new Date(now.setMonth(now.getMonth() + 1)),
			  //subscriptionID: data.subscriptionID,
			  payment_method: 'paypal'
			}
		 }).then((result)=> {
			 if(result) {
				 window.location.reload();
				 setToast("success", "Payment successful!");
			 }
		 });
	};

	const paypalOnError = (err) => {
		setToast("error", err.message);
	}*/
	
	const handleSubmit = async (e) => {

        var params = new URLSearchParams(search);
		var plan_type = params.get('plan_type');
		if(plan_type == '' || plan_type == null || plan_type == 'monthly'){
			var amt = 4.99;
			var dur = 1;
		}else {
			var amt = 49.99;
			var dur = 12;
		}

		e.preventDefault();
		setLoading(true);
		
		if (!stripe || !elements) {
		  // Stripe.js has not yet loaded.
		  return;
		}
		
		const res = await APIRequest.request({
			method: 'POST',
			headers: authHeader(),
			url: `/pay-subscription`,
			data: {
			  email: user.email,
			  name: user.username,
			  cardnumber: card.cardnumber,
			  expmonth: card.expmonth,
			  expyear: card.expyear,
			  cvc: card.cvc,
			}
		 });
		
		if (res.data["error"]) {
		    setToast("error", res.data["error"]);
			setLoading(true);
		} else {
			//const clientSecret = res.data["client_secret"];
			let now = new Date();
			
			APIRequest.request({
				method: 'PUT',
				headers: authHeader(),
				url: `/update-subscription`,
				data: {
				  email: user.email,
				  plan: "paid",
				  plan_expires: new Date(now.setMonth(now.getMonth() + dur)),
				  payment_method: 'stripe',
				  //clientSecret: clientSecret
				}
			 }).then((result)=> {
				 if(result) {
					 window.location.reload();
					 setToast("success", "Payment successful!");
				 }
			 });
		}
	}
	


	const onChangeValue = (event) => {
      
		if(event.target.value == 'Monthly'){
           window.location.href = '/plans?plan_type=monthly&open_popup=true';
		}else {
			window.location.href = '/plans?plan_type=yearly&open_popup=true';	  
		}
	  //console.log( amt+" - "+ amt);
	}

	const handleChange = (event) => {

		const { name, value } = event.target;
		if(name === 'cardnumber') {
			if(event.target.value.length <= 16) {
				setCard((prevState) => {
				  return {
					...prevState,
					[name]: value,
				  };
				});
			}
		}else if(name === 'expmonth') {
			if(event.target.value.length <= 2) {
				setCard((prevState) => {
				  return {
					...prevState,
					[name]: value,
				  };
				});
			}
		}else if(name === 'expyear') {
			if(event.target.value.length <= 2) {
				setCard((prevState) => {
				  return {
					...prevState,
					[name]: value,
				  };
				});
			}
		}else if(name === 'cvc') {
			if(event.target.value.length <= 3) {
				setCard((prevState) => {
				  return {
					...prevState,
					[name]: value,
				  };
				});
			}
		}
	  };
	
	  var params = new URLSearchParams(search);
	  var p_type = params.get('plan_type');

	  if(p_type == '' || p_type == null || p_type == 'monthly'){
		var type = 'monthly';
	}else {
		var type = 'yearly';
	}	  

	return (
		
		<>
		{loading ? <Loading/> : ""}
		<ul className="lh-1-6 fs-14 text-left mb-3">
          <li className="d-block"><input type="radio" value="Monthly" name="duration" checked={type === "monthly"} onChange={onChangeValue}/>&nbsp;Monthly Membership: <span className="color-green">$4.99</span></li>
          <li className="d-block"><input type="radio" value="Yearly" name="duration" checked={type === "yearly"}   onChange={onChangeValue}/>&nbsp;Yearly Membership: <span className="color-green">$49.99</span></li>
        </ul>

		<form onSubmit={handleSubmit}>
		  <input
			  type="text"
			  name="cardnumber"
			  className="form-control"
			  placeholder="Card Number"
			  value={card.cardnumber}
			  onChange={handleChange}
			  required
			/>
			<br/>
			<input
			  type="number"
			  name="expmonth"
			  className="form-control"
			  placeholder="MM"
			  value={card.expmonth}
			  onChange={handleChange}
			  required
			/>
			<br/>
			<input
			  type="number"
			  name="expyear"
			  className="form-control"
			  placeholder="YY"
			  value={card.expyear}
			  onChange={handleChange}
			  required
			/>
			<br/>
			<input
			  type="text"
			  name="cvc"
			  className="form-control"
			  placeholder="CVC"
			  value={card.cvc}
			  onChange={handleChange}
			  required
			/>
		  <button className="button red" type="submit" disabled={!stripe || !elements || loading} style={{float: "right", margin: "15px"}}>
			Pay
		  </button>
		</form>
		
		{/*<PayPalBtn
			amount = "4.99"
			currency = "USD"
			createSubscription={paypalSubscribe}
			onApprove={paypalOnApprove}
			catchError={paypalOnError}
			onError={paypalOnError}
		/>*/}
		  <div>
			<div ref={paypal}></div>
		  </div>
		</>
	  );
};

const StripePyamentModal = (props) => {
	const {show} = props;

	const stripePromise = loadStripe(
      "pk_live_51J0WxRDcKm6ZidcNeYQFGCZLuYiqTEozqmyf7yKwJiAVpfmZM4UxY4571KNr6OSEJ9J3ze4uSGENfzoSiTWi0k6m00WXopn9v1"
    );
	
	/*const stripePromise = loadStripe(
      "pk_test_51JAVTQSFV5KJzjzOXHxe9wfYQnymQqjiAlGRfUH4YGvDv8ECo61sxba9Ko2thFNhuwHqpMnwFtm6uTK4F7agZTeX00tUcKkVuZ"
    );*/
	
	const closeModal = () => {
	  window.location.href = '/plans';
    }
	


	return (
	<>
		<Modal show={show}>
			<div className="modal-header">
        <h5 className="modal-title">Add Your Payment Details</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">

        <Elements stripe={stripePromise}>
					<CheckoutForm/>
			  </Elements>
      </div>
		</Modal>
	</>
	);
}

export default StripePyamentModal;