import React, { Component, Fragment } from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { stateMappings } from '../redux/stateMappings';
import { signOut } from '../redux/auth/thunks';
import { setWeerdyModal } from '../redux/modal/thunks';
import { APIRequest } from '../lib/apiRequest';
import authHeader from '../Utilities/auth-header';
import { setToast } from '../lib/toast';
import environment from '../environments/environment.dev';
import ImageSlider from '../components/ImageSlider';

class Profile extends Component {
  constructor(props) {
    super(props);
	
    this.state = {
		bio: "",
		interests: "",
		facts: "",
		info: {},
		imgSrc: [],
		imgCollection: [],
		topPicks: [],
		userId: this.props.match.params,
		isEdited: 0,
		isBlocked: "",
		saved:[],
		plan_expires:"",
		save_text:"Save Profile",
		succ:"Profile Saved!",
    };
  }
   
	componentDidMount() {
		const {user} = this.props;
		const {userId} = this.state;
		const interestedGender = user.physicalGender == 'male' ? 'female' : 'male';
		

		APIRequest.request({
			method: 'GET',
			headers: authHeader(),
			url: `/get-profile/${this.props.user._id}`
		 }).then(result => {
			 this.setState({plan_expires: result.data.plan_expires});
		 });

		APIRequest.request({
			method: 'GET',
			headers: authHeader(),
			url: `/get-profile/${userId.id}`
		 }).then(result => {
			 this.setState({info: result.data});
			 this.setState({bio: result.data.bio});
			 this.setState({interests: result.data.interests});
			 this.setState({facts: result.data.facts});
			 //this.setState({imgCollection: result.data.imgCollection});
		 });
		 
		 APIRequest.request({
			method: "GET",
			headers: authHeader(),
			url: `/user-images/${userId.id}`,
		  }).then((result) => {
			var arr = [];
			this.setState({ images: result.data });
			result.data.map((value, index) => {
			  //alert(value.picture);
			  arr.push("gallery/" + value.picture);
			});
	  
			this.setState({ imgCollection: arr });
		  });


		 var date = new Date();
		 var day = date.getDate();
		 var month = date.getMonth()+1;
		 var maxYear = (date.getFullYear()-1) - user.maxAge;
		 var minYear = date.getFullYear() - user.minAge;
		 var minYearDate = month+'/'+day+'/'+minYear;
		 var maxYearDate = month+'/'+day+'/'+maxYear;
		 
		 APIRequest.request({
			method: 'POST',
			headers: authHeader(),
			url: `/top-picks`,
			data: {
				interestedGender: interestedGender,
			}
		 }).then(result => {
			 this.setState({
				 topPicks: result ['data']
				 .filter((e) => 
					(new Date(e.birthday).getTime() <= new Date(minYearDate).getTime()) && (new Date(e.birthday).getTime() > new Date(maxYearDate).getTime())
				 )
			  });
		 });
		 
		 APIRequest.request({
			 method: 'POST',
			 headers: authHeader(),
			 url: `/view-profile`,
			 data: {
				 viewId: user._id,
				 viewedId: userId.id
			 }
		 });
		 
		 APIRequest.request({
			method: 'POST',
			headers: authHeader(),
			url: `/check-blocked`,
			data: {
				 UserId: user._id,
				 BlockedId: userId.id
			 }
		 }).then(result => {
			 if(result.data) {
			 this.setState({isBlocked: result.data.blockId});
			 }
		 });

		 APIRequest.request({
      method: "GET",
      headers: authHeader(),
      url: `/view-saved-profile/${user._id}`,
    }).then((result) => {
			this.setState({ saved: result["data"] });
			
            result["data"].map((data, index) => {


                 //alert(data.viewId._id+" "+userId.id);

                 if(data.viewId._id == userId.id){
					this.setState({save_text: "Unsave Profile"});
					this.setState({succ: "Profile Unsaved!"});
				 }
			});

    });


	}

	onChange = event => {
		const {name, value} = event.target;
		this.setState((state) => ({
			...state,
			[name]: value,
		}));
	}
	
  blockUser = event => {
	const {user} = this.props;
	const {userId} = this.state;
	
	APIRequest.request({
		 method: 'POST',
		 headers: authHeader(),
		 url: `/block-profile`,
		 data: {
			UserId: user._id,
			BlockedId: userId.id
		 }
	}).then(result => {
		setToast('success', 'You block this person');
		window.location.reload(false);
	});
  }
  
  saveProfile = event => {

	if(this.state.succ != 'Profile Unsaved!' && this.state.saved.length > 5){
		setToast("warning", "Maximum Profiles Saved!");
		return;
	}

	const { user } = this.props;	
	var id = event.target.attributes.getNamedItem('id').value;
  
	APIRequest.request({
	  method: 'PUT',
	  headers: authHeader(),
	  url: `/save-profile`,
	  data: {
		viewedId: user._id,
		viewId: id
	  }
	 }).then(result => {
		setToast('success', this.state.succ);

		setTimeout(function(){
			window.location.reload(false);
		 }, 2000);

	 });
  
  };


  reportUser = event => {
	const {user} = this.props;
	const {userId} = this.state;
	
	APIRequest.request({
		 method: 'POST',
		 headers: authHeader(),
		 url: `/report-profile`,
		 data: {
			UserId: user._id,
			reportedId: userId.id
		 }
	}).then(result => {
		setToast('success', 'Your report has been send!');
	});
  }

  render() {
    const { signOut, setWeerdyModal,user } = this.props;
    const { username, email, plan, _id, plan_expires } = this.props.user || {};
	const { isEdited,bio,interests,facts,info,imgCollection,topPicks,isBlocked,userId } = this.state;


    const date1 = new Date(this.state.plan_expires);
    const elapsed = date1.getTime(); // Elapsed time in MS
    const current_date = new Date();
    const current_elapsed = current_date.getTime(); // Elapsed time in MS
	
    console.log("Dts");
	console.log(this.state.plan_expires)
	console.log(date1+"  "+current_date);

    return (
      <Fragment>
	  {!isBlocked ? 
        <section className="bg-img profile">
          <div className="container">
              <div className="row content">
			  
                <div className="col-lg-4 col-md-4 col-sm-12 p-0">
                <div className="position-relative bg-white">                	
                  <div className="main-overlay"></div>
                  <div className="top-detail">
                    <h5>
                    	<a href={"/gallery/"+userId.id}>Photos</a>
                    </h5>
                    <h5>{info.username}</h5>
                  </div>
				{/*(imgCollection[0]) ? 
                  <img className="main-profile w-100 d-block" src={environment.img_url+"userImages/"+imgCollection[0]} alt="profile" /> : 
				  <img className="main-profile w-100 d-block" src="../assets/images/img.png" alt="profile" />*/}
				  {(imgCollection[0]) ? 
                  <ImageSlider dp={imgCollection}/> : 
				  <img className="main-profile w-100 d-block" src="../assets/images/img.png" alt="profile" />}
                  <div className="bottom-detail text-center">
                    <div className="tags">
                      <h5 className="color-red">PG: <span style={{textTransform: "capitalize"}}>{info.physicalGender}</span></h5>
                      <h5 className="color-blue">IA: <span style={{textTransform: "capitalize"}}>{info.identifiedGender}</span></h5>
                      <h5 className="color-lt-green">L: <span style={{textTransform: "capitalize"}}>{info.letter}</span></h5>
                    </div>

					
                    {(_id != info._id) ? 
	                            <>
									<div className="tags_btn mt-4">
										<a onClick={this.blockUser} className="button red rounded md fw-bold">Block User</a>
										<a onClick={this.reportUser} className="button red rounded md fw-bold">Report User</a>
									</div>
									{!elapsed || current_elapsed > elapsed ? (
                                    <div></div>
                                    ) : (
										<div className="tags_btn mt-4">
										<a id={info._id} onClick={this.saveProfile} className="button red rounded md fw-bold">{this.state.save_text}</a>
									</div>	

									)}	
									</>												
					: ''}	
		
                  </div>
                </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 profile-details">
                <div className="row top-picks">
				{topPicks.map((info, index) => 
				<>
				{index <= 3 && userId.id != info._id ? 
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center" key={index}>
                    <div className="position-relative h-100">
					<a href={"/user-profile/"+info._id}>
					{(info.picture) ?
                      <img className="w-100 d-block" src={environment.img_url+"userImages/"+info.picture} alt="profile" /> : 
					  <img className="w-100 d-block" src="../assets/images/img.png" alt="profile" />}
                      <h5>{info.username}</h5>
                      <div className="overlay"></div>
					  </a>
                    </div>
                  </div>
				: ''}
				</>
                  )}
                  <div className="col-lg-12 text-center p-2 pb-0">
                    <h4 className="fs-shrinkhand">Top Picks For You</h4>
                  </div>
				  <a href={`/message/`+userId.id} className="button red rounded md mt-12 sm:mt-8 fw-bold">Message</a>
                </div>
				<form>
                  <div className="row">
                    <div className="col-lg-12 bio-data">
                      <h5 className="color-red fw-bold mb-2 fs-raleway">Bio:</h5>
					  {(isEdited === 0) ? <p>{(info.bio) ? info.bio : 'No bio found!'}</p> : <textarea
                        name="bio"
						value={bio}
						onChange={this.onChange}
					  ></textarea>}
                    </div>
                    <div className="col-lg-12 bio-data">
                      <h5 className="color-red fw-bold mb-2 fs-raleway">Interests:</h5>
					  {(isEdited === 0) ? <p>{(info.interests) ? info.interests : 'No interest found!'}</p> : <textarea
                        name="interests"
						value={interests}
						onChange={this.onChange}
					  ></textarea>}
                    </div>
                    <div className="col-lg-12 bio-data">
                      <h5 className="color-red fw-bold mb-2 fs-raleway">Random facts About me:</h5>
					  {(isEdited === 0) ? <p>{(info.facts) ? info.facts : 'No fact found!'}</p> : <textarea
                        name="facts"
						value={facts}
						onChange={this.onChange}
					  ></textarea>}
                    </div>
                  </div>
				  {(isEdited === 0) ? '' : <input type="submit" className="button red rounded md mt-12 sm:mt-8 fw-bold" value="Edit" />}
				  </form>
                </div>
              </div>
          </div>
        </section>
		: <section className="bg-img profile">
			<div className="container">
              <div className="row">
              <div className="col-lg-12 text-center">
						  	<h2 className="fs-shrinkhand text-white">{(isBlocked === _id) ? 'You blocked this person' : 'You have been blocked by this person'}</h2>
						  </div>
			  </div>
			</div>
		   </section>
		}
      </Fragment>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);

  return {
    user: sm.user,
  };
};

const mapActionsToProps = {
  signOut,
  setWeerdyModal,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(Profile);

const SectionContainer = styled.section`
  .plan {
    text-transform: capitalize;

    span {
      cursor: pointer;

      &:hover {
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        color: inherit;
      }
    }
  }
`


