import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import socketIOClient from "socket.io-client";
import moment from "moment";
import classnames from "classnames";
import commonUtilites from "../../Utilities/common";
import {
  useGetGlobalMessages,
  useSendGlobalMessage,
  useGetConversationMessages,
  useSendConversationMessage,
} from "../../Services/chatService";
import { authenticationService } from "../../Services/authenticationService";
import environment from "../../environments/environment.dev";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  headerRow: {
    maxHeight: 60,
    zIndex: 5,
  },
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    color: theme.palette.primary.dark,
  },
  messageContainer: {
    height: "100%",
    display: "flex",
    alignContent: "flex-end",
  },
  messagesRow: {
    maxHeight: "calc(100vh - 200px)",
    overflowY: "auto",
  },
  newMessageRow: {
    width: "100%",
    padding: theme.spacing(0, 2, 1),
  },
  messageBubble: {
    padding: 10,
    border: "1px solid white",
    backgroundColor: "#f2f2f2",
    borderRadius: "0 10px 10px 10px",
    marginTop: 8,
    maxWidth: "25em",
  },
  messageBubbleRight: {
    borderRadius: "10px 0 10px 10px",
  },
  inputRow: {
    display: "flex",
    alignItems: "flex-end",
  },
  form: {
    width: "100%",
  },
  avatar: {
    margin: theme.spacing(1, 1.5),
  },
  listItem: {
    display: "flex",
    width: "100%",
  },
  listItemRight: {
    flexDirection: "row-reverse",
  },
}));

const ChatBox = (props) => {
  const [currentUserId] = useState(
    // authenticationService.currentUserValue.userId
    props.currentUser._id
  );
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [lastMessage, setLastMessage] = useState(null);

  const getGlobalMessages = useGetGlobalMessages();
  const sendGlobalMessage = useSendGlobalMessage();
  const getConversationMessages = useGetConversationMessages();
  const sendConversationMessage = useSendConversationMessage();

  let chatBottom = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    reloadMessages();
    scrollToBottom();
  }, [lastMessage, props.scope, props.conversationId]);

  useEffect(() => {
    const socket = socketIOClient(environment.socket_url);
    socket.on("messages", (data) => setLastMessage(data));
  }, []);

  const reloadMessages = () => {
    if (props.scope === "Global Chat") {
      getGlobalMessages().then((res) => {
        setMessages(res);
      });
    } else if (props.scope !== null && props.conversationId !== null) {
      getConversationMessages(props.user._id).then((res) => setMessages(res));
    } else {
      setMessages([]);
    }
  };

  const scrollToBottom = () => {
    chatBottom.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.scope === "Global Chat") {
      sendGlobalMessage(newMessage).then(() => {
        setNewMessage("");
      });
    } else {
      sendConversationMessage(props.user._id, newMessage).then((res) => {
        setNewMessage("");
      });
    }
  };

  return (
    <>
    {/* <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.headerRow}>
        <Paper className={classes.paper} square elevation={2}>
          <Typography color="inherit" variant="h6">
            {props.scope}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Grid container className={classes.messageContainer}>
          <Grid item xs={12} className={classes.messagesRow}>
            {messages && (
              <List>
                {messages.map((m) => (
                  <ListItem
                    key={m._id}
                    className={classnames(classes.listItem, {
                      [`${classes.listItemRight}`]:
                        m.fromObj[0]?._id === currentUserId,
                    })}
                    alignItems="flex-start"
                  >
                    <ListItemAvatar className={classes.avatar}>
                      <Avatar>
                        {commonUtilites.getInitialsFromName(m.fromObj[0]?.username)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      classes={{
                        root: classnames(classes.messageBubble, {
                          [`${classes.messageBubbleRight}`]:
                            m.fromObj[0]?._id === currentUserId,
                        }),
                      }}
                      // primary={m.fromObj[0] && m.fromObj[0]?.username}
                      secondary={<React.Fragment>{m.body}</React.Fragment>}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            <div ref={chatBottom} />
          </Grid>
          <Grid item xs={12} className={classes.inputRow}>
            <form onSubmit={handleSubmit} className={classes.form}>
              <Grid
                container
                className={classes.newMessageRow}
                alignItems="flex-end"
              >
                <Grid item xs={11}>
                  <TextField
                    id="message"
                    label="Message"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton type="submit">
                    <SendIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid> */}

    <div className="chat-history">
      <div className="messages">
        <ul className="chat-list scrollable-grey">
          {messages.map((m) => (
            <li key={m._id} className={m.fromObj[0]?._id === currentUserId ? "sender" : "receiver"}>
              <div className="msg-img">
                {/* <img src="/assets/images/tom.jpg" alt="" /> */}
                <Avatar style={{ height: '40px', width: '40px' }}>
                  {commonUtilites.getInitialsFromName(m.fromObj[0]?.username)}
                </Avatar>
                <div className="bubble-time text-right">
                  {`${moment(parseInt(m.date)).fromNow()}, ${moment(parseInt(m.date)).format("h:mm a")} `}
                  <i className="fas fa-check color-green"></i>
                </div>
              </div>
              <div className="msg-bubble">
                  <p>{m.body}</p>
              </div>
            </li>
          ))}
          <div ref={chatBottom} />
        </ul>
      </div>
      <form
        className="position-relative"
        id="chat-message"
        onSubmit={handleSubmit}
      >
	  {/*<div className="additonal-options">
          <i id="plus-btn" className="fas fa-plus-circle"></i>
          <div className="extra-features">
            <ul>
              <li><a href="#"><i className="fas fa-file-edit"></i></a></li>
              <li><a href="#"><i className="fas fa-images"></i></a></li>
              <li><a href="#"><i className="fas fa-camera"></i></a></li>
              <li><a href="#"><i className="fad fa-user-plus"></i></a></li>
              <li><a href="#"><i className="fas fa-map-marker-alt"></i></a></li>
            </ul>
          </div>
	  </div>*/}
        <input
          id="message"
          type="text"
          className="chat-input"
          placeholder="Type something..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <div className="click-options">
          <ul>
            <li>
              <button type="submit" className="send-btn"><i className="fab fa-telegram-plane"></i></button>
            </li>
          </ul>
        </div>
      </form>
    </div>
    </>
  );
};

export default ChatBox;
