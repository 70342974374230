import React, { Fragment } from 'react';


const Explain = () =>
{
  return (
    <Fragment>
      <section>
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center">
              <h2 className="fs-shrinkhand">Lovacane Explained</h2>
              <img className="my-4 w-100" src="/assets/images/heart-design.png" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="pt-0">
          <div className="container">
              <div className="row ">
                <div className="col-lg-12 col-sm-12 mx-auto">

                  <div className="legal-content p-8 sm:p-4 border-white radius-md mt-8">
                      <div className="scrollable text-center">
                        <h3 className="mb-8 fs-roboto">Welcome</h3>
                        <p className="my-8">Lovacane is an online platform that allows men and women of the LGBTQ community to date each other. For example, if an LGBTQ man Prefers dating men, Lovacane will pair him with an LGBTQ woman who identifies as a man (and vice Versa). The Lovacane theory and website are the creations of Ritchie Knox. Ritchie believes that select individuals within the LGBTQ community have an organic curiosity or yearning for the opposite sex (i.e., despite the dominant theory on LGBTQ people). While Ritchie does not deny the idea that individiuals are born with an innate desire for the same sex, he seeks to allow individuals of the LGBTQ community to fall in love with the opposite sex without altering who they are as people.</p>




                        <h3 className="my-8 fs-roboto">Cookies</h3>
                        <p className="mt-2">We employ the use of cookies. By accessing the website name, you agreed to use cookies.</p>

                        <p className="my-8">Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>


                        <h3 className="my-8 fs-roboto">License</h3>
                        <p className="mt-2">Unless otherwise stated, Company Name and/or its licensors own the intellectual property rights for all material on Website Name. All intellectual property rights are reserved. You may access this from Website Name for your own personal use subjected to restrictions set in these terms and conditions.</p>


                        <h3 className="my-8 fs-roboto">You must not:</h3>

                        <p className="mt-2">Republish material from Website Name
                        Sell, rent or sub-license material from Website Name
                        Reproduce, duplicate or copy material from Website Name
                        Redistribute content from Website Name
                        This Agreement shall begin on the date hereof.</p>

                        <p className="my-8">Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Company Name does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Company Name,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Company Name shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>

                        <p className="mt-2">You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;
                        The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</p>
                        <p className="my-8">The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</p>
                        <p className="my-8">The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</p>
                      </div>
                  </div>
                </div>
              </div>
          </div>
      </section>
    </Fragment>
  );
}

export default Explain;

