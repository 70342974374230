import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import styled from 'styled-components';
import Avatar from "@material-ui/core/Avatar";

import AdminSidebar from '../components/AdminSidebar';
import { stateMappings } from '../redux/stateMappings';
import { getInitialsFromName } from '../lib/stringHelper';

class Chat extends Component {
  render() {
    const { users } = this.props;

    return (
      <section className="mulitcolor-bg bg-img profile">
        <div className="container chat">
            <div className="row ">
              <div className="col-lg-12 bgcolor-red">
                <h4>Say Something ff</h4>
              </div>
            </div>
            <div className="row bgcolor-white">
              <div className="col-lg-4 col-md-4">
                <div className="search chat-search mt-8">
                  <form>
                    <input className="form-control people-input" type="text" placeholder="Search people" />
                    <i className="fas fa-search"></i>
                  </form>
                </div>
                <ul className="people-list scrollable-grey">
                  {users.map(user =>
                    <li className="user align-items-center">
                      <div className="uer-profile position-relative">
                        {/* <img src="/assets/images/profile-main.jpg" alt="profile" />
                        <span className="status-icon green"></span> */}
                        <Avatar style={{ height: '40px', width: '40px' }}>{getInitialsFromName(user.username)}</Avatar>
                      </div>
                      <div className="personal-details">
                        <h6>
                          {user.username}
                          <div className="chat-status">
                            {/* <p className="chat-time">8:28 AM</p> */}
                          </div>
                        </h6>
                        {/* <p className="fs-poppins">How are you?</p> */}
                      </div>
                      
                    </li>
                  )}
                    <li className="user align-items-center">
                      <div className="uer-profile position-relative">
                        <img src="/assets/images/profile-main.jpg" alt="profile" />
                        <span className="status-icon green"></span>
                      </div>
                      <div className="personal-details">
                        <h6>Emmina Slams</h6>
                        <p className="fs-poppins">How are you?</p>
                      </div>
                      <div className="chat-status">
                        <p className="chat-time">8:28 AM</p>
                      </div>
                    </li>
                    <li className="user align-items-center active">
                      <div className="uer-profile position-relative">
                        <img src="/assets/images/tom.jpg" alt="profile" />
                        <span className="status-icon green"></span>
                      </div>
                      <div className="personal-details">
                        <h6>Tom</h6>
                        <p className="fs-poppins">How are you?</p>
                      </div>
                      <div className="chat-status">
                        <p className="chat-time">8:28 AM</p>
                      </div>
                    </li>
                    <li className="user align-items-center">
                      <div className="uer-profile position-relative">
                        <img src="/assets/images/profile-main.jpg" alt="profile" />
                        <span className="msg-status">3</span>
                      </div>
                      <div className="personal-details">
                        <h6>Emmina Slams <i className="fas fa-circle color-red online"></i></h6>
                        <p className="fs-poppins">How are you?</p>
                      </div>
                      <div className="chat-status">
                        <p className="chat-time">8:28 AM</p>
                      </div>
                    </li>
                    <li className="user align-items-center">
                      <div className="uer-profile position-relative">
                        <img src="/assets/images/profile-main.jpg" alt="profile" />
                        <span className="status-icon green"></span>
                      </div>
                      <div className="personal-details">
                        <h6>Emmina Slams</h6>
                        <p className="fs-poppins">How are you?</p>
                      </div>
                      <div className="chat-status">
                        <p className="chat-time">8:28 AM</p>
                      </div>
                    </li>
                    <li className="user align-items-center">
                      <div className="uer-profile position-relative">
                        <img src="/assets/images/profile-main.jpg" alt="profile" />
                        <span className="status-icon green"></span>
                      </div>
                      <div className="personal-details">
                        <h6>Emmina Slams</h6>
                        <p className="fs-poppins">How are you?</p>
                      </div>
                      <div className="chat-status">
                        <p className="chat-time">8:28 AM</p>
                      </div>
                    </li>
                    <li className="user align-items-center">
                      <div className="uer-profile position-relative">
                        <img src="/assets/images/profile-main.jpg" alt="profile" />
                        <span className="status-icon green"></span>
                      </div>
                      <div className="personal-details">
                        <h6>Emmina Slams</h6>
                        <p className="fs-poppins">How are you?</p>
                      </div>
                      <div className="chat-status">
                        <p className="chat-time">8:28 AM</p>
                      </div>
                    </li>
                    <li className="user align-items-center">
                      <div className="uer-profile position-relative">
                        <img src="/assets/images/profile-main.jpg" alt="profile" />
                        <span className="status-icon green"></span>
                      </div>
                      <div className="personal-details">
                        <h6>Emmina Slams</h6>
                        <p className="fs-poppins">How are you?</p>
                      </div>
                      <div className="chat-status">
                        <p className="chat-time">8:28 AM</p>
                      </div>
                    </li>
              </ul>
              </div>
              <div className="col-lg-8 col-md-8 mt-8">
                <div className="chat-history">
                  <div className="messages">
                    <ul className="chat-list scrollable-grey">
                      <li className="sender">
                         <div className="msg-img">
                           <img src="/assets/images/tom.jpg" alt="" />
                           <div className="bubble-time text-right">Today 9:01 am <i className="fas fa-check color-green"></i></div>
                         </div>
                         <div className="msg-bubble">
                            <p>Hi Heni!</p>
                            <p>How are you doing today?</p>
                         </div>
                      </li>
                      <li className="receiver">
                         <div className="msg-img">
                           <img src="/assets/images/profile-main.jpg" alt="" />
                           <div className="bubble-time text-right">Today 9:01 am <i className="fas fa-check color-green"></i></div>
                         </div>
                         <div className="msg-bubble">
                            <p>Hi Jarat! Nice to hear you again!</p>
                            <p>I’m fine, thank you! And what about you? Is everything ok?</p>
                         </div>
                      </li>
                      <li className="sender">
                         <div className="msg-img">
                           <img src="/assets/images/tom.jpg" alt="" />
                           <div className="bubble-time text-right">Today 9:02 am <i className="fas fa-check color-green"></i></div>
                         </div>
                         <div className="msg-bubble">
                            <p>Hi Heni!</p>
                            <p>How are you doing today?</p>
                         </div>
                      </li>
                      <li className="receiver">
                         <div className="msg-img">
                           <img src="/assets/images/profile-main.jpg" alt="" />
                           <div className="bubble-time text-right">Today 9:01 am <i className="fas fa-check color-green"></i></div>
                         </div>
                         <div className="msg-bubble">
                            <p>Hi Jarat! Nice to hear you again!</p>
                            <p>I’m fine, thank you! And what about you? Is everything ok?</p>
                         </div>
                      </li>
                      <li className="sender">
                         <div className="msg-img">
                           <img src="/assets/images/tom.jpg" alt="" />
                           <div className="bubble-time text-right">Today 9:03 am <i className="fas fa-check color-green"></i></div>
                         </div>
                         <div className="msg-bubble">
                            <p>Yep, thanks!</p>
                            <p>May I ask you a favour?</p>
                            <p>What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s?</p>
                         </div>
                      </li>
                      <li className="receiver">
                         <div className="msg-img">
                           <img src="/assets/images/profile-main.jpg" alt="" />
                           <div className="bubble-time text-right">Today 9:03 am <i className="fas fa-check color-green"></i></div>
                         </div>
                         <div className="msg-bubble">
                            <p>Hi Jarat! Nice to hear you again!</p>
                            <p>I’m fine, thank you! And what about you? Is everything ok?</p>
                         </div>
                      </li>
                    </ul>
                  </div>
                  <form className="position-relative" id="chat-message">
                    <div className="additonal-options">
                      <i id="plus-btn" className="fas fa-plus-circle"></i>
                      <div className="extra-features">
                        <ul>
                          <li><a href="#"><i className="fas fa-file-edit"></i></a></li>
                          <li><a href="#"><i className="fas fa-images"></i></a></li>
                          <li><a href="#"><i className="fas fa-camera"></i></a></li>
                          <li><a href="#"><i className="fad fa-user-plus"></i></a></li>
                          <li><a href="#"><i className="fas fa-map-marker-alt"></i></a></li>

                        </ul>
                      </div>
                    </div>
                    <input type="text" className="chat-input" placeholder="Type something..." />
                    <div className="click-options">
                      <ul>
                        <li><button type="submit" className="send-btn"><i className="fab fa-telegram-plane"></i></button></li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-12 mb-8">
                <div className="chating-person-info text-center">

                      <div className="person-dp mt-8 mb-8">
                        <img src="/assets/images/tom.jpg" alt="profile" />
                        <h5 className="fw-bold name">Tom</h5>
                      </div>
                      <div className="user-actions">
                        <a href="#"><i className="fas fa-phone-alt"></i></a>
                        <a href="#"><i className="fas fa-video color-green"></i></a>
                      </div>
                </div>
                <div className="chat-attachments">
                    <div className="mt-8 mb-4 d-flex align-items-center justify-content-between ">
                      <h6 className="fw-bold text-center p-4 bgcolor-red w-100 color-white ">About <span className="name">Tom</span></h6>
                    </div>
                    <div className="chat-files">
                      <p className="fs-11">Shared Photos</p>

                      <div className="row mt-2">
                        <div className="col-lg-4 col-md-4 col-sm-4">
                          <img className="w-100" src="/assets/images/i1.jpg" alt="" />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                          <img className="w-100" src="/assets/images/i2.jpg" alt="" />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                          <img className="w-100" src="/assets/images/i3.jpg" alt="" />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                          <img className="w-100" src="/assets/images/i4.jpg" alt="" />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                          <img className="w-100" src="/assets/images/i5.jpg" alt="" />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                          <img className="w-100" src="/assets/images/i1.jpg" alt="" />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                          <img className="w-100" src="/assets/images/i3.jpg" alt="" />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                          <img className="w-100" src="/assets/images/i2.jpg" alt="" />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                          <img className="w-100" src="/assets/images/i1.jpg" alt="" />
                        </div>
                      </div>

                    </div>
                </div>
              </div> */}
          </div>
        </div>
    </section>
    );
  }
}

const mapStateToProps = ( state ) => {
    const sm = stateMappings(state);

    return {
      isAuthenticated: sm.isAuthenticated,
      isAdmin: sm.isAdmin,
      currentUser: sm.user,
      users: (sm.users || []).filter(user => user._id !== sm.user?._id),
    };
};

export default connect(
  mapStateToProps,
  undefined,
)(Chat);


const DivContainer = styled.div`
  padding-bottom: 80px;

  .video-table {
    padding: 20px 0px;
  }
`
