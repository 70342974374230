import { getLovacaneUsers } from '../redux/admin/thunks';
import { setUserSettings } from '../redux/auth/thunks';


const setupDataManager = (userId) => async (dispatch, getState) => {
  await Promise.all([
    // dispatch(getLovacaneUsers()),
    dispatch(setUserSettings(userId))
  ]);
};

export default setupDataManager;
