//This file holds the API calls that hit the /user route for DRY purposes
import { APIRequest } from '../../lib/apiRequest';

export const getWeerds = async () =>
  APIRequest.request({
    method: 'GET',
    url: `/weerds`
  });

export const postWeerd = async (weerd) =>
  APIRequest.request({
    method: 'POST',
    url: `/weerd`,
    data: {
      ...weerd,
    }
  });

export const putWeerd = async (id, weerd) =>
  APIRequest.request({
    method: 'PUT',
    url: `/weerd/${id}`,
    data: {
      ...weerd,
    }
  });

export const deleteWeerd = async (id) =>
  APIRequest.request({
    method: 'DELETE',
    url: `/weerd/${id}`,
  });
