import { authenticationService } from '../Services/authenticationService';
import { getToken } from '../lib/apiRequest';

function authHeader() {
    const currentUser =
      // authenticationService.currentUserValue;
      getToken('user');
    if (currentUser && currentUser.token) {
        return {
            Authorization: `Bearer ${currentUser.token}`,
            'Content-Type': 'application/json',
        };
    } else {
        return {};
    }
}

export default authHeader;
