import {
  getUsers
} from './apiCalls';
import {
  setUsers,
  setLoadingStatus,
  setErrorMessage,
} from './actions';


const setStatus = (status, message) => (
  dispatch
) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const getLovacaneUsers = () => async (
  dispatch,
) => {
  dispatch(setStatus(true, ''));

  try {
    const { data: users } = await getUsers();

    dispatch(setUsers(users));
    dispatch(setStatus(false, ''));
  } catch (error) {
    dispatch(setStatus(false, error.message));
  }
};

