import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Countdown from "react-countdown";

import { stateMappings } from "../redux/stateMappings";
import { weerdGets, weerdEdit } from "../redux/weerd/thunks";
import { setToast } from "../lib/toast";

const TIMELIMIT = 15000;

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeWeerd: this.props.weerds.length - 1,
      start: Date.now() + TIMELIMIT,
      categoryActive: false,
    };

    this.categoriesRef = React.createRef();
  }

  componentDidMount() {
    // this.props.gets();
  }

  componentDidUpdate(prevProps, prevStates) {
    // Typical usage (don't forget to compare props):
    if (this.props.weerds.length !== prevProps.weerds.length) {
      this.setActiveWeerd(this.props.weerds.length - 1);
    }
  }

  setActiveWeerd = (index) => {
    this.setState(
      {
        activeWeerd: index,
        start: Date.now() + TIMELIMIT,
      },
      () => {
        this.actions("view", false);
      }
    );
  };

  actions = (type, isToast = true) => {
    const { weerds, edit, currentUser, isAuthenticated } = this.props;

    if (!isAuthenticated) {
      if (isToast) {
        // setToast('warning', 'You should login first to do this action.');
      }
      return;
    }

    const weerd = weerds[this.state.activeWeerd];
    if (weerd.author._id === currentUser._id) {
      setToast("info", "This is your weerd");
      return;
    }

    if ((weerd[`${type}s`] || []).includes(currentUser._id)) {
      if (type !== "view") {
        // setToast('info', `You already selected ${type}`);
      }
      return;
    }

    edit(
      {
        ...weerd,
        [`${type}s`]: [...weerd[`${type}s`], currentUser._id],
        [type]: currentUser._id,
        type,
      },
      false
    );
  };

  timeRenderer = ({ seconds, completed }) => {
    if (completed) {
      // Render a completed state
      const index = this.state.activeWeerd - 1;
      this.setActiveWeerd(index < 0 ? this.props.weerds.length - 1 : index);
    }

    return <Fragment> {`00:${seconds < 10 ? "0" : ""}${seconds}`} </Fragment>;
  };

  activeCategoryDropBox = () => {
    this.setState({
      categoryActive: !this.state.categoryActive,
    });
  };

  categoryScroll = () => {
    this.categoriesRef &&
      this.categoriesRef.current.scrollIntoView({
        behavior: "smooth",
      });
  };

  render() {
    const { weerds, loading } = this.props;
    const { activeWeerd, start, categoryActive } = this.state;

    return (
      <Fragment>
        <section className="bg-img hero">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-3 col-3 left">
                <img className="w-100" src="/assets/images/man.png" alt="" />
              </div>{" "}
              <div className="col-md-6 col-6 p-0 text-center">
                <img
                  className="w-100"
                  src="/assets/images/home-door.png"
                  onClick={() => this.props.history.push("/register")}
                  alt=""
                />
                <div className="signup-form bgcolor-black radius p-4 mt-24">
                  <form>
                    <div className="signup-buttons">
                      <a className="button fb" href="#">
                        {" "}
                        <i className="fab fa-facebook-f"> </i> Sign up with
                        Facebook
                      </a>
                      <a className="button google" href="#">
                        {" "}
                        <i className="fab fa-google"> </i> Connecting with
                        Google
                      </a>
                    </div>{" "}
                    <div className="my-4 text-center">
                      <img
                        className="or-image"
                        src="/assets/images/or-image.png"
                        alt=""
                      />
                    </div>{" "}
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label"> I 'm a</label>{" "}
                      <div className="col-sm-9">
                        <select className="form-control" required>
                          <option> Man Looking for a Woman </option>{" "}
                          <option> Woman Looking for a Man </option>{" "}
                        </select>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label"> Name </label>{" "}
                      <div className="col-sm-9">
                        <input
                          type="text"
                          placeholder="Enter Name"
                          className="form-control"
                          required
                        />
                      </div>{" "}
                    </div>
                    <div className="form-group row birthday">
                      <label className="col-sm-3 col-form-label">
                        {" "}
                        Birthday{" "}
                      </label>{" "}
                      <div className="col-sm-3">
                        <select className="form-control" required>
                          <option> Month </option> <option> January </option>{" "}
                          <option> February </option> <option> March </option>{" "}
                          <option> April </option> <option> May </option>{" "}
                          <option> June </option> <option> July </option>{" "}
                          <option> August </option> <option> September </option>{" "}
                          <option> October </option> <option> November </option>{" "}
                          <option> December </option>{" "}
                        </select>{" "}
                      </div>{" "}
                      <div className="col-sm-3">
                        <select className="form-control" required>
                          <option> Day </option> <option> 1 </option>{" "}
                          <option> 2 </option> <option> 3 </option>{" "}
                          <option> 4 </option> <option> 5 </option>{" "}
                          <option> 6 </option> <option> 7 </option>{" "}
                          <option> 8 </option> <option> 9 </option>{" "}
                          <option> 10 </option> <option> 11 </option>{" "}
                          <option> 12 </option> <option> 13 </option>{" "}
                          <option> 14 </option> <option> 15 </option>{" "}
                          <option> 16 </option> <option> 17 </option>{" "}
                          <option> 18 </option> <option> 19 </option>{" "}
                          <option> 20 </option> <option> 21 </option>{" "}
                          <option> 22 </option> <option> 23 </option>{" "}
                          <option> 24 </option> <option> 25 </option>{" "}
                          <option> 26 </option> <option> 27 </option>{" "}
                          <option> 28 </option> <option> 29 </option>{" "}
                          <option> 30 </option>{" "}
                        </select>{" "}
                      </div>{" "}
                      <div className="col-sm-3">
                        <select className="form-control" required>
                          <option> Year </option> <option> 1990 </option>{" "}
                          <option> 1991 </option> <option> 1992 </option>{" "}
                          <option> 1993 </option> <option> 1994 </option>{" "}
                          <option> 1995 </option> <option> 1996 </option>{" "}
                          <option> 1997 </option> <option> 1998 </option>{" "}
                          <option> 1999 </option> <option> 2000 </option>{" "}
                          <option> 2001 </option> <option> 2002 </option>{" "}
                          <option> 2003 </option> <option> 2004 </option>{" "}
                          <option> 2005 </option> <option> 2006 </option>{" "}
                          <option> 2007 </option> <option> 2008 </option>{" "}
                          <option> 2009 </option> <option> 2010 </option>{" "}
                          <option> 2011 </option> <option> 2012 </option>{" "}
                          <option> 2013 </option> <option> 2014 </option>{" "}
                          <option> 2015 </option> <option> 2016 </option>{" "}
                          <option> 2017 </option> <option> 2018 </option>{" "}
                          <option> 2019 </option> <option> 2020 </option>{" "}
                          <option> 2021 </option>{" "}
                        </select>{" "}
                      </div>{" "}
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label"> Email </label>{" "}
                      <div className="col-sm-9">
                        <input
                          type="email"
                          placeholder="Email Address"
                          className="form-control"
                          required
                        />
                      </div>{" "}
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label"> Name </label>{" "}
                      <div className="col-sm-9">
                        <input
                          type="password"
                          placeholder="At least 8 characters"
                          className="form-control"
                          required
                        />
                      </div>{" "}
                    </div>
                    <div className="form-group row my-4">
                      <div className="col-lg-12">
                        <button type="submit" className="button red md w-100">
                          {" "}
                          Sign up For Free Today!{" "}
                        </button>{" "}
                      </div>{" "}
                    </div>
                    <div className="form-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="gridCheck"
                        />
                        <label
                          className="form-check-label fs-11"
                          for="gridCheck"
                        >
                          I want to recieve news, updates and offersfrom
                          Lovacane by creating an account, you agree to our{" "}
                          <a href="#"> Terms of service </a> &{" "}
                          <a href="#">Privacy Policy</a> .{" "}
                        </label>{" "}
                      </div>{" "}
                    </div>
                  </form>{" "}
                </div>{" "}
              </div>{" "}
              <div className="col-md-3 col-3 right">
                <img className="w-100" src="/assets/images/girl.png" alt="" />
              </div>{" "}
            </div>
          </div>{" "}
        </section>

        <section className="py-4 d-none">
          <div className="row slider">
            <div className="slide">
              {" "}
              <a href="#">
                {" "}
                <img src="/assets/images/clients/cnn.png" alt="" />{" "}
              </a>
            </div>
            <div className="slide">
              {" "}
              <a href="#">
                {" "}
                <img
                  src="/assets/images/clients/dailymail-logo.jpg"
                  alt=""
                />{" "}
              </a>
            </div>
            <div className="slide">
              {" "}
              <a href="#">
                {" "}
                <img
                  src="/assets/images/clients/dating-advice.png"
                  alt=""
                />{" "}
              </a>
            </div>
            <div className="slide">
              {" "}
              <a href="#">
                {" "}
                <img src="/assets/images/clients/derstand.png" alt="" />{" "}
              </a>
            </div>
            <div className="slide">
              {" "}
              <a href="#">
                {" "}
                <img src="/assets/images/clients/g-news.png" alt="" />{" "}
              </a>
            </div>
            <div className="slide">
              {" "}
              <a href="#">
                {" "}
                <img
                  src="/assets/images/clients/evening-Standard.png"
                  alt=""
                />{" "}
              </a>
            </div>
            <div className="slide">
              {" "}
              <a href="#">
                {" "}
                <img
                  src="/assets/images/clients/financial-times-logo.png"
                  alt=""
                />{" "}
              </a>
            </div>
            <div className="slide">
              {" "}
              <a href="#">
                {" "}
                <img src="/assets/images/clients/ntv.png" alt="" />{" "}
              </a>
            </div>
            <div className="slide">
              {" "}
              <a href="#">
                {" "}
                <img src="/assets/images/clients/Vogue-logo.png" alt="" />{" "}
              </a>
            </div>
          </div>
        </section>

        <section className="bg-img reviews">
          <div className="container">
            <div className="row color-white">
              <div className="col-lg-12 mx-auto text-center testimonials">
                <img
                  className="testimonials-img"
                  src="/assets/images/reviews-image.png"
                  alt=""
                />
                <div className="rating-box first d-none">
                  <div>
                    <div className="dp">
                      <img src="/assets/images/dp-1.jpg" alt="" />
                    </div>{" "}
                  </div>{" "}
                  <div className="text-left">
                    <ul>
                      <li>
                        {" "}
                        <i className="fas fa-star"> </i>
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-star"> </i>
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-star"> </i>
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-star"> </i>
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-star"> </i>
                      </li>
                    </ul>{" "}
                    <p>
                      {" "}
                      Got 38 likes a day!This app is perfect and full of genuine
                      people.I 'd definetely recommend Lovacane to all of my
                      friends.
                    </p>{" "}
                  </div>{" "}
                </div>
                <div className="rating-box second d-none">
                  <div>
                    <div className="dp">
                      <img src="/assets/images/dp-2.jpg" alt="" />
                    </div>{" "}
                  </div>{" "}
                  <div className="text-left">
                    <ul>
                      <li>
                        {" "}
                        <i className="fas fa-star"> </i>
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-star"> </i>
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-star"> </i>
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-star"> </i>
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-star"> </i>
                      </li>
                    </ul>{" "}
                    <p>
                      {" "}
                      Got 38 likes a day!This app is perfect and full of genuine
                      people.I 'd definetely recommend Lovacane to all of my
                      friends.
                    </p>{" "}
                  </div>{" "}
                </div>{" "}
              </div>

              <div className="col-lg-8 mt-8 mx-auto text-center d-none">
                <h2 className="fs-georgia-regular"> Meet Nee People </h2>{" "}
                <p className="my-8">
                  {" "}
                  Rating at 4.8 / 5, Lovacane is the most unique and upscale
                  community in the world.It makes it simple to meet new people,
                  match with the best - quality singles, and cultivate important
                  relationships.{" "}
                </p>{" "}
                <a className="button red" href="#">
                  {" "}
                  Download Now For Free{" "}
                </a>{" "}
              </div>
            </div>{" "}
          </div>{" "}
        </section>

        <section>
          <div className="container">
            <div className="row mb-8">
              <div className="col-lg-12 text-center">
                <h2 className="fs-shrinkhand"> How Lovacane Works </h2>{" "}
              </div>{" "}
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="text-center my-8 works">
                  <img src="/assets/images/work-1.png" alt="" />
                  <h4 className="fs-shrinkhand mt-4">
                    {" "}
                    1. Create a Profile{" "}
                  </h4>{" "}
                </div>{" "}
              </div>{" "}
              <div className="col-lg-6">
                <div className="text-center my-8 works">
                  <img src="/assets/images/work-2.png" alt="" />
                  <h4 className="fs-shrinkhand mt-4"> 2. Look Around </h4>{" "}
                </div>{" "}
              </div>{" "}
              <div className="col-lg-6">
                <div className="text-center my-8 works">
                  <img src="/assets/images/work-3.png" alt="" />
                  <h4 className="fs-shrinkhand mt-4">
                    {" "}
                    3. Form Connections{" "}
                  </h4>{" "}
                </div>{" "}
              </div>{" "}
              <div className="col-lg-6">
                <div className="text-center my-8 works">
                  <img src="/assets/images/work-4.png" alt="" />
                  <h4 className="fs-shrinkhand mt-4">
                    {" "}
                    4. Fall in Lovacane{" "}
                  </h4>{" "}
                </div>{" "}
              </div>
            </div>{" "}
          </div>{" "}
        </section>

        <section className="bg-img mulitcolor-bg py-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center color-white">
                <h2 className="fs-shrinkhand d-none">
                  {" "}
                  The Lovacane Revolution{" "}
                </h2>
                <h4 className="fs-shrinkhand d-none">
                  {" "}
                  Marriage Hall of Fame{" "}
                </h4>
                <img
                  className="podium"
                  src="/assets/images/revolution.png"
                  alt=""
                />
                <img
                  className="podium"
                  src="/assets/images/podium.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-img mulitcolor-bg py-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center color-white">
                <h2 className="fs-shrinkhand  d-none">
                  {" "}
                  Marriage Hall of Fame{" "}
                </h2>
                <img
                  className="podium"
                  src="/assets/images/marriage-hof.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-img mulitcolor-bg py-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center color-white">
                <h2 className="fs-shrinkhand d-none"> Spread the Love! </h2>
                <h4 className="fs-shrinkhand my-8 d-none">
                  {" "}
                  1. Find Love on Lovacane.com{" "}
                </h4>
                <h4 className="fs-shrinkhand my-8 d-none"> 2. Get Married </h4>
                <h4 className="fs-shrinkhand my-8 d-none">
                  {" "}
                  3. Send Photos to LovacaneCEO @gmail.com{" "}
                </h4>
                <h4 className="fs-shrinkhand my-8 d-none">
                  {" "}
                  4. Join the Marriage Hall of Fame{" "}
                </h4>
                <h4 className="fs-shrinkhand my-8 d-none">
                  {" "}
                  5. Let Us Show the World Your Love{" "}
                </h4>
                <img
                  className="podium"
                  src="/assets/images/spread-the-love.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const sm = stateMappings(state);
  // const query = new URLSearchParams(ownProps.location.search);
  const categoryName =
    // query.get('category');
    decodeURIComponent(
      window.location.search.replace(
        new RegExp(
          "^(?:.*[&\\?]" +
            encodeURIComponent("category").replace(/[\.\+\*]/g, "\\$&") +
            "(?:\\=([^&]*))?)?.*$",
          "i"
        ),
        "$1"
      )
    );
  return {
    isAuthenticated: sm.isAuthenticated,
    weerds: (sm.weerds || []).filter(
      ({ category }) => !categoryName || category === categoryName
    ),
    currentUser: sm.user || {},
    loading: sm.loading,
  };
};

const mapActionsToProps = {
  gets: weerdGets,
  edit: weerdEdit,
};

export default connect(mapStateToProps, mapActionsToProps)(Landing);
