import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import socketIOClient from "socket.io-client";

import { useGetUsers } from "../../Services/userService";
import commonUtilites from "../../Utilities/common";
import environment from "../../environments/environment.dev";

const useStyles = makeStyles((theme) => ({
  subheader: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  globe: {
    backgroundColor: theme.palette.primary.dark,
  },
  subheaderText: {
    color: theme.palette.primary.dark,
  },
  list: {
    maxHeight: "calc(100vh - 183px)",
    overflowY: "auto",
  },
  avatar: {
    margin: theme.spacing(0, 3, 0, 1),
  },
}));

const Users = (props) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState(null);
  const getUsers = useGetUsers();

  useEffect(() => {
    getUsers().then((res) => setUsers(res));
  }, [newUser]);

  useEffect(() => {
    const socket = socketIOClient(environment.socket_url);
    socket.on("users", (data) => {
      setNewUser(data);
    });
  }, []);

  return (
    <ul className="people-list scrollable-grey my-4">
      {/* <List className={classes.list}>
        <React.Fragment>
          {(users || [])
          .filter(user => user._id !== props.currentUser._id)
          .map((u) => (
            <ListItem
              className={classes.listItem}
              key={u._id}
              onClick={() => {
                props.setUser(u);
                props.setScope(u.username);
              }}
              button
            >
              <ListItemAvatar className={classes.avatar}>
                <Avatar>{commonUtilites.getInitialsFromName(u.username)}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={u.username} />
            </ListItem>
          ))}
        </React.Fragment>
      </List> */}

      {users && (
        <React.Fragment>
          {(users || []).filter(user => user._id !== props.currentUser._id)
          .map((u) => (
            <li
              key={u._id}
              className="user align-items-center"
              onClick={() => {
                props.setUser(u);
                props.setScope(u.username);
              }}
            >
              <div className="uer-profile position-relative">
                <Avatar style={{ height: '40px', width: '40px' }}>
                  {commonUtilites.getInitialsFromName(u.username)}
                </Avatar>
                {/* <img src="/assets/images/profile-main.jpg" alt="profile" /> */}
                <span className="status-icon green"></span>
              </div>
              <div className="personal-details">
                <h6>
                  {u.username}
                </h6>
              </div>
            </li>
          ))}
        </React.Fragment>
      )}
    </ul>
  );
};

export default Users;
