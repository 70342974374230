import React, { Component, Fragment } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';

import { stateMappings } from '../redux/stateMappings';
import { signUp } from '../redux/auth/thunks';
import { setWeerdyModal } from '../redux/modal/thunks';
import { setToast } from '../lib/toast';

Modal.setAppElement('#root');

class Register extends Component {
  constructor(props) {
    super(props);


    var latitude = this.getCookie("lova_lat_cookie");
    var longitude = this.getCookie("lova_long_cookie");

    this.state = {
      username: '',
      birthday: '',
      birthmonth: '',
      birthyear: '',
      gender: 'male',
      identifiedGender: 'male',
      physicalGender: 'female',
      letter: 'L',
      email: '',
      password: '',
      password_confirm: '',
      role: 'user',
      isChecked: false,
      passwordHidden: true,
	  state: '',
	  postcode: '',
    minAge: 18,
    maxAge: 70,
    distance:0,
    latitude:latitude,
    longitude:longitude,
    };
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
     
    this.props.history.push("/profile");
  }

   getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }



  onChange = ({ target: { name, value } }) =>
    this.setState(state => ({
      ...state,
      [name]: value,
    }));


    onChange1 = event => {

      const {name, value} = event.target;
      this.setState((state) => ({
        ...state,
        [name]: value,
      }));
    } 

  handleSubmit = async (e) => {
    e.preventDefault();

    var latitude = this.getCookie("lova_lat_cookie");
    var longitude = this.getCookie("lova_long_cookie");

    const {
      password_confirm,
      passwordHidden,
      isChecked,
      email,
      birthday,
      birthmonth,
      birthyear,
      ...candidate
    } = this.state;
    const { signUp, history } = this.props;

    if (!birthday || !birthmonth || !birthyear) {
      setToast('error', "Please enter birthday.");
      return;
    }

    if (this.state.password?.length < 8) {
      setToast('error', "Password should be 8 characters at least.");
      return;
    }

    if (password_confirm !== this.state.password) {
      setToast('error', "Re-type password doesn't match Password");
      return;
    }

    if (!isChecked) {
      setToast('error', "You should agree our Terms of service & Privacy Policy.");
      return;
    }
   
    try {
      await signUp({
        ...candidate,
        email: email.toLowerCase(),
        latitude:latitude,
        longitude:longitude,
        birthday: `${birthmonth}/${birthday}/${birthyear}`
      });
      history.push('/login');
    } catch (error) {
      return;
    }
  };

  setPasswordVisibility = () => {
    this.setState({
      passwordHidden: !this.state.passwordHidden,
    });
  }

  handleCheckChange = (e) => {
    this.setState({
      isChecked: e.target.checked,
    });
  }

  render() {
    const {
      open,
      isOpen,
      customStyles,
      isAuthenticated,
    } = this.props;
    const {
      username,
      email,
      password,
      password_confirm,
      gender,
      physicalGender,
      identifiedGender,
      letter,
      birthday,
      birthmonth,
      birthyear,
      passwordHidden,
	  state,
	  postcode,
    latitude,
    longitude
    } = this.state;

    return (
      <Fragment>
        <section className="bg-img hero register">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 left">
                <img className="w-100" src="/assets/images/man.png" alt="" />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 center text-center register-lg-6">
                <img className="w-70 cursor signup-image" src="/assets/images/signup-btn.png" alt="" style={{ display: "none" }} />
                <div className="signup-form bgcolor-black radius p-4 mt-24" style={{ display: 'block'} }>
                  <form onSubmit={this.handleSubmit}>
                    {/* <div className="signup-buttons">
                      <a className="button fb" href="#"><i className="fab fa-facebook-f"></i> Sign up with Facebook</a>
                      <a className="button google" href="#"><i className="fab fa-google"></i> Connecting with Google</a>
                    </div>
                    <div className="my-4 text-center">
                      <img className="or-image" src="/assets/images/or-image.png" alt="" />
                    </div> */}
                    {/*<div className="form-group row">
                      <label className="col-sm-3 col-form-label">I'm a</label>
                      <div className="col-sm-9">
                        <select className="form-control" value={gender} name="gender" onChange={this.onChange}>
                          <option value="male">Man Looking for a Woman</option>
                          <option value="female">Woman Looking for a Man</option>
                        </select>
                      </div>
                  </div> */}
					<div className="form-group row">
                      <label className="col-sm-3 col-form-label">Your Location</label>
                      <div className="col-sm-9">
                        <select className="form-control" name="state" id="state" value={state} onChange={this.onChange} required>
							<option value="">Select State</option>
              <option value="Alabama">Alabama</option>
	<option value="Alaska">Alaska</option>
	<option value="Arizona">Arizona</option>
	<option value="Arkansas">Arkansas</option>
	<option value="California">California</option>
	<option value="Colorado">Colorado</option>
	<option value="Connecticut">Connecticut</option>
	<option value="DDelawareE">Delaware</option>
	<option value="District Of Columbia">District Of Columbia</option>
	<option value="Florida">Florida</option>
	<option value="Georgia">Georgia</option>
	<option value="Hawaii">Hawaii</option>
	<option value="Idaho">Idaho</option>
	<option value="Illinois">Illinois</option>
	<option value="Indiana">Indiana</option>
	<option value="Iowa">Iowa</option>
	<option value="Kansas">Kansas</option>
	<option value="Kentucky">Kentucky</option>
	<option value="Louisiana">Louisiana</option>
	<option value="Maine">Maine</option>
	<option value="Maryland">Maryland</option>
	<option value="Massachusetts">Massachusetts</option>
	<option value="Michigan">Michigan</option>
	<option value="Minnesota">Minnesota</option>
	<option value="Mississippi">Mississippi</option>
	<option value="Missouri">Missouri</option>
	<option value="Montana">Montana</option>
	<option value="Nebraska">Nebraska</option>
	<option value="Nevada">Nevada</option>
	<option value="New Hampshire">New Hampshire</option>
	<option value="New Jersey">New Jersey</option>
	<option value="New Mexico">New Mexico</option>
	<option value="New York">New York</option>
	<option value="North Carolina">North Carolina</option>
	<option value="North Dakota">North Dakota</option>
	<option value="Ohio">Ohio</option>
	<option value="Oklahoma">Oklahoma</option>
	<option value="Oregon">Oregon</option>
	<option value="Pennsylvania">Pennsylvania</option>
	<option value="Rhode Island">Rhode Island</option>
	<option value="South Carolina">South Carolina</option>
	<option value="South Dakota">South Dakota</option>
	<option value="Tennessee">Tennessee</option>
	<option value="Texas">Texas</option>
	<option value="Utah">Utah</option>
	<option value="Vermont">Vermont</option>
	<option value="Virginia">Virginia</option>
	<option value="Washington">Washington</option>
	<option value="West Virginia">West Virginia</option>
	<option value="Wisconsin">Wisconsin</option>
	<option value="Wyoming">Wyoming</option>
						</select>
                      </div>
                    </div>
					<div className="form-group row">
                      <label className="col-sm-3 col-form-label">Your Zipcode</label>
                      <div className="col-sm-9">
                          <input data-latitude="" data-longitude="" type="text" name="postcode" id="postcode" value={postcode} onChange={this.onChange} placeholder="Enter Zipcode" className="form-control" required/>
                      </div>
                    </div>
                   <input type="hidden" name="latitude" id="latitude" value={latitude}  />
                   <input type="hidden" name="longitude" id="longitude" value={longitude}  /> 
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Name</label>
                      <div className="col-sm-9">
                          <input type="text" placeholder="Enter Name" className="form-control" name="username" value={username} onChange={this.onChange} required />
                      </div>
                    </div>
                    <div className="form-group row birthday">
                      <label className="col-sm-3 col-form-label">Birthday</label>
                      <div className="col-sm-3">
                          <select className="form-control" name="birthmonth" value={birthmonth} onChange={this.onChange} required>
                            <option value="">Month</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                      </div>
                      <div className="col-sm-3">
                          <select className="form-control" name="birthday" value={birthday} onChange={this.onChange} required>
                            <option value="">Day</option>
                            <option value="01">1</option>
                            <option value="02">2</option>
                            <option value="03">3</option>
                            <option value="04">4</option>
                            <option value="05">5</option>
                            <option value="06">6</option>
                            <option value="07">7</option>
                            <option value="08">8</option>
                            <option value="09">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                          </select>
                      </div>
                      <div className="col-sm-3">
                          <select className="form-control" name="birthyear" value={birthyear} onChange={this.onChange} required>
                            <option value="">Year</option>
							<option value="1952">1952</option>
							<option value="1953">1953</option>
							<option value="1954">1954</option>
							<option value="1955">1955</option>
							<option value="1956">1956</option>
							<option value="1957">1957</option>
							<option value="1958">1958</option>
							<option value="1959">1959</option>
							<option value="1960">1960</option>
							<option value="1961">1961</option>
							<option value="1962">1962</option>
							<option value="1963">1963</option>
							<option value="1964">1964</option>
							<option value="1965">1965</option>
							<option value="1966">1966</option>
							<option value="1967">1967</option>
							<option value="1968">1968</option>
							<option value="1969">1969</option>
							<option value="1970">1970</option>
							<option value="1971">1971</option>
							<option value="1972">1972</option>
							<option value="1973">1973</option>
							<option value="1974">1974</option>
							<option value="1975">1975</option>
							<option value="1976">1976</option>
							<option value="1977">1977</option>
							<option value="1978">1978</option>
							<option value="1979">1979</option>
							<option value="1980">1980</option>
							<option value="1981">1981</option>
							<option value="1982">1982</option>
							<option value="1983">1983</option>
							<option value="1984">1984</option>
							<option value="1985">1985</option>
							<option value="1986">1986</option>
							<option value="1987">1987</option>
							<option value="1988">1988</option>
							<option value="1989">1989</option>
							<option value="1990">1990</option>
                            <option value="1991">1991</option>
                            <option value="1992">1992</option>
                            <option value="1993">1993</option>
                            <option value="1994">1994</option>
                            <option value="1995">1995</option>
                            <option value="1996">1996</option>
                            <option value="1997">1997</option>
                            <option value="1998">1998</option>
                            <option value="1999">1999</option>
                            <option value="2000">2000</option>
                            <option value="2001">2001</option>
                            <option value="2002">2002</option>
                            <option value="2003">2003</option>
                            <option value="2004">2004</option>
                            <option value="2005">2005</option>
                            <option value="2006">2006</option>
                            <option value="2007">2007</option>
                            <option value="2008">2008</option>
                            <option value="2009">2009</option>
                            <option value="2010">2010</option>
                            <option value="2011">2011</option>
                            <option value="2012">2012</option>
                            <option value="2013">2013</option>
                            <option value="2014">2014</option>
                            <option value="2015">2015</option>
                            <option value="2016">2016</option>
                            <option value="2017">2017</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                          </select>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Physical Gender</label>
                      <div className="col-sm-9">
                        <select className="form-control" value={physicalGender} name="physicalGender" onChange={this.onChange}>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">I Identify As</label>
                      <div className="col-sm-9">
                        <select className="form-control" value={identifiedGender} name="identifiedGender" onChange={this.onChange}>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Which Letter Are You</label>
                      <div className="col-sm-9">
                        <select className="form-control" value={letter} name="letter" onChange={this.onChange}>
                          <option value="L">(L)esbian</option>
                          <option value="G">(G)ay</option>
                          <option value="B">(B)isexual</option>
                          <option value="TW">(T)ransexual Woman</option>
                          <option value="TM">(T)ransexual Man</option>
						  <option value="Q">(Q)ueer</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Email</label>
                      <div className="col-sm-9">
                          <input type="email" placeholder="Email Address" className="form-control" name="email" value={email} onChange={this.onChange} required />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Password</label>
                      <div className="col-sm-9">
                          <input type="password" placeholder="At least 8 characters" className="form-control" name="password" value={password} onChange={this.onChange} required />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Confirm password</label>
                      <div className="col-sm-9">
                          <input type="password" placeholder="At least 8 characters" className="form-control" name="password_confirm" value={password_confirm} onChange={this.onChange} required />
                      </div>
                    </div>
                    <div className="form-group row my-4">
                      <div className="col-lg-12">
                        <button type="submit" className="button red md w-100">Sign up For Free Today!</button>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="gridCheck" onChange={this.handleCheckChange} />
                        <label className="form-check-label fs-11" htmlFor="gridCheck">
                          I want to recieve news, updates and offers from Lovacane by creating an account, you agree to our <a href="#">Terms of service</a> &amp; <a href="#">Privacy Policy</a>.
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 right">
                <img className="w-100" src="/assets/images/girl.png" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="py-4 d-none">
            <div  className="row slider">
              <div className="slide"><a href="#"><img src="/assets/images/clients/cnn.png" alt="" /></a></div>
              <div className="slide"><a href="#"><img src="/assets/images/clients/dailymail-logo.jpg" alt="" /></a></div>
              <div className="slide"><a href="#"><img src="/assets/images/clients/dating-advice.png" alt="" /></a></div>
              <div className="slide"><a href="#"><img src="/assets/images/clients/derstand.png" alt="" /></a></div>
              <div className="slide"><a href="#"><img src="/assets/images/clients/g-news.png" alt="" /></a></div>
              <div className="slide"><a href="#"><img src="/assets/images/clients/evening-Standard.png" alt="" /></a></div>
              <div className="slide"><a href="#"><img src="/assets/images/clients/financial-times-logo.png" alt="" /></a></div>
              <div className="slide"><a href="#"><img src="/assets/images/clients/ntv.png" alt="" /></a></div>
              <div className="slide"><a href="#"><img src="/assets/images/clients/Vogue-logo.png" alt="" /></a></div>
            </div>

        </section>

      <section className="bg-img reviews">
              <div className="container">
                  <div className="row color-white">

                    <div className="col-lg-12 mx-auto text-center testimonials">
                      <img className="testimonials-img" src="/assets/images/reviews-image.png" alt="" />

                      <div className="rating-box first">
                        <div>
                          <div className="dp">
                            <img src="/assets/images/dp-1.jpg" alt="" />
                          </div>
                        </div>
                          <div className="text-left">
                          <ul>
                              <li><i className="fas fa-star"></i></li>
                              <li><i className="fas fa-star"></i></li>
                              <li><i className="fas fa-star"></i></li>
                              <li><i className="fas fa-star"></i></li>
                              <li><i className="fas fa-star"></i></li>
                          </ul>
                          <p>Got 38 likes a day! This app is perfect and full of genuine people. I'd definetely recommend Lovacane to all of my friends.</p>
                        </div>
                      </div>

                      <div className="rating-box second">
                        <div>
                          <div className="dp">
                            <img src="/assets/images/dp-2.jpg" alt="" />
                          </div>
                        </div>
                        <div className="text-left">
                          <ul>
                              <li><i className="fas fa-star"></i></li>
                              <li><i className="fas fa-star"></i></li>
                              <li><i className="fas fa-star"></i></li>
                              <li><i className="fas fa-star"></i></li>
                              <li><i className="fas fa-star"></i></li>
                          </ul>
                          <p>Got 38 likes a day! This app is perfect and full of genuine people. I'd definetely recommend Lovacane to all of my friends.</p>
                        </div>
                      </div>
                    </div>


                    <div className="col-lg-8 mt-8 mx-auto text-center">
                      <h2 className="fs-georgia-regular">Meet Nee People</h2>
                      <p className="my-8">Rating at 4.8/5, Lovacane is the most unique and upscale community in the world. It makes it simple to meet new people, match with the best-quality singles, and cultivate important relationships. </p>
                      <a className="button red" href="#">Download Now For Free</a>
                    </div>

                  </div>
              </div>
          </section>




          <section>
            <div className="container">
              <div className="row mb-8">
                <div className="col-lg-12 text-center">
                  <h2 className="fs-shrinkhand">How Lovacane Works</h2>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="text-center my-8 works">
                    <img src="/assets/images/work-1.png" alt="" />
                    <h4 className="fs-shrinkhand mt-4">1.Create a Profile</h4>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="text-center my-8 works">
                    <img src="/assets/images/work-2.png" alt="" />
                    <h4 className="fs-shrinkhand mt-4">2. Look Around</h4>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="text-center my-8 works">
                    <img src="/assets/images/work-3.png" alt="" />
                    <h4 className="fs-shrinkhand mt-4">3. Form Connections</h4>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="text-center my-8 works">
                    <img src="/assets/images/work-4.png" alt="" />
                    <h4 className="fs-shrinkhand mt-4">4. Fall in Lovacane</h4>
                  </div>
                </div>

              </div>
            </div>
          </section>





        <section className="bg-img mulitcolor-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center color-white">
                  <h2 className="fs-shrinkhand">The Lovacane Revolution</h2>
                  <h4 className="fs-shrinkhand">Marriage Hall of Fame</h4>
                </div>
              </div>

              <div className="row my-16">
                <div className="col-lg-12 text-center">
                  <img className="podium" src="/assets/images/podium.png" alt="" />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 text-center color-white">
                  <h2 className="fs-shrinkhand">Marriage Hall of Fame</h2>
                </div>
              </div>

              <div className="row my-16">
                <div className="col-lg-12 text-center">
                  <img className="podium" src="/assets/images/question-mark.png" alt="" />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 text-center color-white">
                  <h2 className="fs-shrinkhand">Spread the Love!</h2>

                    <h4 className="fs-shrinkhand my-8">1. Find Love on Lovacane.com</h4>
                    <h4 className="fs-shrinkhand my-8">2. Get Married</h4>
                    <h4 className="fs-shrinkhand my-8">3. Send Photos to LovacaneCEO@gmail.com</h4>
                    <h4 className="fs-shrinkhand my-8">4. Join the Marriage Hall of Fame</h4>
                    <h4 className="fs-shrinkhand my-8">5. Let Us Show the World Your Love</h4>
                </div>
              </div>

            </div>
          </section>
      </Fragment>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
  };
};

const mapActionsToProps = {
  signUp,
  setModal: setWeerdyModal,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(Register);
