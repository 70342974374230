import React, { Fragment } from 'react';


const Explain = () =>
{
    return (
        <Fragment>
            <section>
                <div className="container">
                    <div className="row align-items-center gap-2">
                        <div className="col-md-4 order-2 order-md-1">
                            <img className="w-100" src="/assets/images/theory-1.png" alt="" />
                        </div>
                        <div className="col-md-4 order-1 order-md-2">
                            <img className="w-100" src="/assets/images/theory-2.png" alt="" />
                        </div>
                        <div className="col-lg-10 col-12 offset-lg-1 text-center order-3">
                            <p className="m-0">
                                The Lovacane Theory asserts that a man of the LGBTQ community can enjoy love and happiness with a woman of the LGBTQ community. Within this new concept, the male and female members switch gender roles. It is believed that this fresh take on dating will revolutionize how gender norms are perceived in the LGBTQ community and the world.
                            </p>
                        </div>
                        <div className="col-lg-10 col-12 offset-lg-1 order-4">
                            <div className="row gap-2 justify-content-between">
                                <div className="col-lg-4 col-md-5">
                                    <img className="w-100" src="/assets/images/theory-3.png" alt="" />
                                </div>
                                <div className="col-lg-4 col-md-5">
                                    <img className="w-100" src="/assets/images/theory-4.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-0">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12 col-sm-12 mx-auto text-center">
                            <h3 className="mb-8 fs-roboto">Contact Us</h3>
                            <p className="mt-2">For any questions or inquiries, email us at <a href="mailto:lovacaneceo@gmail.com">lovacaneceo@gmail.com</a>.</p>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}
export default Explain;

