import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { stateMappings } from "../redux/stateMappings";
import { signOut } from "../redux/auth/thunks";
import { setWeerdyModal } from "../redux/modal/thunks";
import { APIRequest } from "../lib/apiRequest";
import authHeader from "../Utilities/auth-header";
import { setToast } from "../lib/toast";
import environment from "../environments/environment.dev";
import ImageSlider from "../components/ImageSlider";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bio: "",
      interests: "",
      facts: "",
      info: {},
      imgSrc: [],
      imgCollection: [],
      topPicks: [],
      isEdited: 0,
      physicalGender: "",
      identifiedGender: "",
      letter: "",
      images: [],
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const interestedGender = user.physicalGender == "male" ? "female" : "male";

    APIRequest.request({
      method: "GET",
      headers: authHeader(),
      url: `/get-profile/${user._id}`,
    }).then((result) => {
      this.setState({ info: result.data });
      this.setState({ bio: result.data.bio });
      this.setState({ interests: result.data.interests });
      this.setState({ facts: result.data.facts });

      this.setState({ physicalGender: result.data.physicalGender });
      this.setState({ identifiedGender: result.data.identifiedGender });
      this.setState({ letter: result.data.letter });
    });

    APIRequest.request({
      method: "GET",
      headers: authHeader(),
      url: `/user-images/${user._id}`,
    }).then((result) => {
      var arr = [];
      this.setState({ images: result.data });
      result.data.map((value, index) => {
        //alert(value.picture);
        arr.push("gallery/" + value.picture);
      });

      this.setState({ imgCollection: arr });
    });

    var date = new Date();
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var maxYear = date.getFullYear() - 1 - user.maxAge;
    var minYear = date.getFullYear() - user.minAge;
    var minYearDate = month + "/" + day + "/" + minYear;
    var maxYearDate = month + "/" + day + "/" + maxYear;

    APIRequest.request({
      method: "POST",
      headers: authHeader(),
      url: `/top-picks`,
      data: {
        interestedGender: interestedGender,
      },
    }).then((result) => {
      this.setState({
        topPicks: result["data"].filter(
          (e) =>
            new Date(e.birthday).getTime() <= new Date(minYearDate).getTime() &&
            new Date(e.birthday).getTime() > new Date(maxYearDate).getTime()
        ),
      });
    });
  }

  Edit = (e) => {
    e.preventDefault();
    this.setState({ isEdited: 1 });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  fileSelectedHandler = (event) => {
    const { images } = this.state;

    var len = event.target.files.length;
    const { settings } = this.props;
    const date = new Date(settings.plan_expires);
    const elapsed = date.getTime(); // Elapsed time in MS
    const current_date = new Date();
    const current_elapsed = current_date.getTime(); // Elapsed time in MS
    if (!elapsed || current_elapsed > elapsed) {
      var total_pic = 5;
    } else {
      var total_pic = 10;
    }

    total_pic = total_pic - images.length;

    if (len <= total_pic) {
      var reader = "";
      this.setState({
        imgSrc: [],
      });
      for (var i = 0; i < len; i++) {
        reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = (e) => {
          this.setState((prevState) => ({
            imgSrc: [...prevState.imgSrc, e.target.result],
          }));
        };
      }
    } else {
      if (total_pic == 0) {
        alert("You have already reached your gallery image limit");
      } else {
        alert("Can not upload more than " + total_pic + " images");
      }
    }
  };

  upload = (event) => {
    const { imgSrc } = this.state;
    const { user } = this.props;

    setToast("success", "Uploading in Progress! Please Wait.");

    APIRequest.request({
      method: "PUT",
      headers: authHeader(),
      url: `/update-images`,
      data: {
        imgCollection: imgSrc,
        user: user._id,
      },
    }).then((result) => {
      setToast("success", "Images are uploaded successfully.");
      window.location.reload(false);
    });
  };

  handleSubmit = (e) => {
    e.preventDefault(e);
    const { bio, interests, facts, physicalGender, identifiedGender, letter } =
      this.state;
    const { user } = this.props;
    this.setState({ isEdited: 0 });
    APIRequest.request({
      method: "PUT",
      headers: authHeader(),
      url: `/update-profile`,
      data: {
        bio: bio,
        interests: interests,
        facts: facts,
        user: user._id,
        physicalGender: physicalGender,
        identifiedGender: identifiedGender,
        letter: letter,
      },
    }).then((result) => {
      setToast("success", "Profile has been updated successfully.");
      window.location.reload(false);
    });
  };

  render() {
    const { signOut, setWeerdyModal } = this.props;
    const { username, email, plan, _id } = this.props.user || {};
    const {
      isEdited,
      bio,
      interests,
      facts,
      info,
      imgCollection,
      topPicks,
      physicalGender,
      identifiedGender,
      letter,
    } = this.state;

    const { settings } = this.props;
    const date = new Date(settings.plan_expires);
    const elapsed = date.getTime(); // Elapsed time in MS
    const current_date = new Date();
    const current_elapsed = current_date.getTime(); // Elapsed time in MS
    if (!elapsed || current_elapsed > elapsed) {
      var total_pic = 5;
    } else {
      var total_pic = 10;
    }



    return (
      <Fragment>
        <section className="bg-img profile">
          <div className="container">
            <div className="row content">
              <div className="col-lg-4 col-md-4 col-sm-12 p-0">
                <div className="position-relative bg-white">
                  <div className="main-overlay"></div>
                  <div className="top-detail">
                    <h5>
                      <a href="/user-gallery">Photos</a>
                    </h5>
                    <h5>{username}</h5>
                    <label htmlFor="file-input" style={{ cursor: "pointer" }}>
                      <i
                        className="fas fa-camera"
                        title={"Select up to " + total_pic + " images"}
                      ></i>
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={this.fileSelectedHandler}
                      multiple
                    />
                  </div>
                  {/*(imgCollection[0]) ? 
                  <img className="main-profile w-100 d-block" src={environment.img_url+"userImages/gallery/"+imgCollection[0]} alt="profile" /> : 
				  <img className="main-profile w-100 d-block" src="./assets/images/img.png" alt="profile" />*/}
                  {imgCollection[0] ? (
                    <ImageSlider dp={imgCollection} />
                  ) : (
                    <img
                      className="main-profile w-100 d-block"
                      src="./assets/images/img.png"
                      alt="profile"
                    />
                  )}
                  <div className="bottom-detail">
                    <div className="tags">
                      <h5 className="color-red">
                        {isEdited === 0 ? (
                          <div>
                            PG:{" "}
                            <span style={{ textTransform: "capitalize" }}>
                              {info.physicalGender}
                            </span>
                          </div>
                        ) : (
                          <div>
                            PG:
                            <select
                              className="form-control mt-2"
                              value={physicalGender}
                              name="physicalGender"
                              onChange={this.onChange}
                            >
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </div>
                        )}
                      </h5>
                      <h5 className="color-blue mx-2">
                        {isEdited === 0 ? (
                          <div>
                            IA:{" "}
                            <span style={{ textTransform: "capitalize" }}>
                              {info.identifiedGender}
                            </span>
                          </div>
                        ) : (
                          <div>
                            IG:
                            <select
                              className="form-control mt-2"
                              value={identifiedGender}
                              name="identifiedGender"
                              onChange={this.onChange}
                            >
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </div>
                        )}
                      </h5>
                      <h5 className="color-lt-green">
                        {isEdited === 0 ? (
                          <div>
                            LI:{" "}
                            <span style={{ textTransform: "capitalize" }}>
                              {info.letter}
                            </span>
                            <a
                              href=""
                              className="ml-2"
                              style={{ cursor: "pointer" }}
                              onClick={this.Edit}
                            >
                              <i className="fas fa-edit"></i>
                            </a>
                          </div>
                        ) : (
                          <div>
                            L:
                            <select
                              className="form-control mt-2"
                              value={letter}
                              name="letter"
                              onChange={this.onChange}
                            >
                              <option value="L">(L)esbian</option>
                              <option value="G">(G)ay</option>
                              <option value="B">(B)isexual</option>
                              <option value="TW">(T)ransexual Woman</option>
                              <option value="TM">(T)ransexual Man</option>
                              <option value="Q">(Q)ueer</option>
                            </select>
                          </div>
                        )}
                      </h5>
                    </div>
                    {/*{this.state.imgSrc.length>0 ? '' : imgCollection.map((src,index) => <img src={environment.img_url+"userImages/gallery/"+src} key={index} style={{height: "20%", width: "20%"}} />)}*/}
                    {this.state.imgSrc.map((src, index) => (
                      <img
                        src={src}
                        key={index}
                        style={{ height: "20%", width: "20%" }}
                      />
                    ))}
                    {this.state.imgSrc.length > 0 ? (
                      <div className="text-center mt-3">
                        <a
                          href="#"
                          onClick={this.upload}
                          className="button red rounded fw-bold"
                        >
                          Upload
                        </a>
                      </div>
                    ) : (
                      <div className="text-center mt-3">
                   {!elapsed || current_elapsed > elapsed ? (
                        <a href="/plans" className="button red rounded fw-bold">
                        Go Exclusive
                      </a>
                    
                      ) : (
                        <a href="javascript:void(0);" className="button red rounded fw-bold">
                        Exclusive Member
                      </a>
                      )}

  
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12 profile-details">
                <div className="row top-picks">
                  {topPicks.map((info, index) => (
                    <>
                      {index <= 3 && _id != info._id ? (
                        <div
                          className="col-lg-3 col-md-3 col-sm-6 text-center"
                          key={index}
                        >
                          <div className="position-relative h-100">
                            <a href={"/user-profile/" + info._id}>
                              {info.picture ? (
                                <img
                                  className="w-100 d-block"
                                  src={
                                    environment.img_url +
                                    "userImages/gallery/" +
                                    info.picture
                                  }
                                  alt="profile"
                                />
                              ) : (
                                <img
                                  className="w-100 d-block"
                                  src="./assets/images/img.png"
                                  alt="profile"
                                />
                              )}
                              <h5>{info.username}</h5>
                              <div className="overlay"></div>
                            </a>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                  <div className="col-lg-12 text-center p-2 pb-0">
                    <h4 className="fs-raleway fw-bold color-dark-gray">
                      TOP PICKS FOR YOU
                    </h4>
                  </div>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12 bio-data">
                      <h5 className="color-red fw-bold mb-2 fs-raleway">
                        Bio:{" "}
                        <a
                          href=""
                          style={{ cursor: "pointer" }}
                          onClick={this.Edit}
                        >
                          <i className="fas fa-edit"></i>
                        </a>
                      </h5>
                      {isEdited === 0 ? (
                        <p>{info.bio ? info.bio : "No bio found!"}</p>
                      ) : (
                        <textarea
                          name="bio"
                          value={bio}
                          onChange={this.onChange}
                        ></textarea>
                      )}
                    </div>
                    <div className="col-lg-12 bio-data">
                      <h5 className="color-red fw-bold mb-2 fs-raleway">
                        Interests:
                      </h5>
                      {isEdited === 0 ? (
                        <p>
                          {info.interests
                            ? info.interests
                            : "No interest found!"}
                        </p>
                      ) : (
                        <textarea
                          name="interests"
                          value={interests}
                          onChange={this.onChange}
                        ></textarea>
                      )}
                    </div>
                    <div className="col-lg-12 bio-data">
                      <h5 className="color-red fw-bold mb-2 fs-raleway">
                        Random Facts About Me:
                      </h5>
                      {isEdited === 0 ? (
                        <p>{info.facts ? info.facts : "No fact found!"}</p>
                      ) : (
                        <textarea
                          name="facts"
                          value={facts}
                          onChange={this.onChange}
                        ></textarea>
                      )}
                    </div>
                    <div className="col-lg-12 bio-data">
                      {isEdited === 0 ? (
                        ""
                      ) : (
                        <input
                          type="submit"
                          className="button red rounded md mt-12 sm:mt-8 fw-bold"
                          value="Confirm"
                        />
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    user: sm.user,
    settings: sm.settings,
  };
};

const mapActionsToProps = {
  signOut,
  setWeerdyModal,
};

export default connect(mapStateToProps, mapActionsToProps)(Profile);

const SectionContainer = styled.section`
  .plan {
    text-transform: capitalize;

    span {
      cursor: pointer;

      &:hover {
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        color: inherit;
      }
    }
  }
`;
