import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Card, CardBody } from 'reactstrap';

import { stateMappings } from '../redux/stateMappings';
import { signIn } from '../redux/auth/thunks';
import { APIRequest } from '../lib/apiRequest';
import authHeader from '../Utilities/auth-header';
import { setToast } from '../lib/toast';

class Contact extends Component {
	constructor(props) {
		super(props);

		this.state = {
			userName: '',
			userEmail: '',
			userMessage: ''
		};
	}

	handleSubmit = e => {
		e.preventDefault();
		const {userName, userEmail, userMessage} = this.state;
		
		APIRequest.request({
			method: 'POST',
			headers: authHeader(),
			url: `/send-mail`,
			data: {
				name: userName,
				email: userEmail,
				message: userMessage
			}
	    }).then(result => {
			 this.setState({userName: ''});
			 this.setState({userEmail: ''});
			 this.setState({userMessage: ''});
			 setToast('success', 'Success!');
		});
	}
	
	eventHandler = event => {
		const {name, value} = event.target;
		this.setState((state) => ({
			...state,
			[name]: value,
		}));
	}

  render() {
	const {userName, userEmail, userMessage} = this.state;
    return (
      <Fragment>
        <section>
            <div className="container">
                <div className="row ">
                  <div className="col-lg-12 text-center">
                    <h2 className="fs-shrinkhand">Contact Us</h2>
                  </div>
                </div>
              </div>

          </section>
          <div className="border-bottom-red"></div>
          <section>
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-12">

                    <p className="my-8">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five </p>

                    <div className="contact-form">
                      <form>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                            <input type="text" className="form-control" name="userName" placeholder="Name" onChange={this.eventHandler} value={userName} required />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                            <input type="email" className="form-control" name="userEmail" placeholder="Email Address" onChange={this.eventHandler} value={userEmail} required />
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 my-4">
                            <textarea className="form-control" name="userMessage" placeholder="Message" onChange={this.eventHandler} value={userMessage} required></textarea>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 my-4">
                            <button className="button red d-block w-100" type="submit" onClick={this.handleSubmit}>Send</button>
                          </div>
                        </div>
                      </form>
                    </div>

                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 my-auto sm:mt-8">
                    <img className="w-100" src="/assets/images/contact-image.png" alt="" />
                  </div>

                </div>
            </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
  };
};

const mapActionsToProps = {
  signIn
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(Contact);

