import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import Explain from './pages/Explain';
import Contact from './pages/Contact';
import Profile from './pages/Profile';
import ViewProfile from './pages/ViewProfile';
import Login from './pages/Login';
import Landing from './pages/Landing';
import Register from './pages/Register';
import Filter from './pages/Filter';
import Jitsi from './components/Jitsi';
import Chat from './pages/Chat';
import TestChat from './components/Chat/Chat';
import Plans from './pages/Plans';
import Settings from './pages/Settings';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Theory from './pages/Theory';
import Glossary from './pages/Glossary';
import UserProfile from './pages/UserProfile';
import UserList from './pages/UserList';
import Message from './pages/Message';

import UserGallery from './pages/UserGallery';
import Gallery from './pages/Gallery';

import Notification from './pages/Notification';

// import ContactUs from './pages/ContactUs';
import { stateMappings } from "./redux/stateMappings";

const AuthRoute = ({
  component,
  isAuthenticated,
  ...rest
}) => {
  const routeComponent = (props) => {
    if (isAuthenticated) {
      return React.createElement(component, props);
    } else {
      // setToast('warning', 'Authentication is required.');
      return (
        <Redirect to={{pathname: '/login'}}/>
      );
    }
  };

  return <Route {...rest} render={routeComponent}/>;
};

// const AdminRoute = ({
//   component,
//   isAuthenticated,
//   isAdmin,
//   ...rest
// }) => {
//   const routeComponent = (props) => {
//     if (isAuthenticated && isAdmin) {
//       return React.createElement(component, props);
//     } else {
//       return (
//         <Redirect to={{pathname: '/'}}/>
//       );
//     }
//   };
//   return <Route {...rest} render={routeComponent}/>;
// };

const Router = (props) => (
  <BrowserRouter>
    <Switch >
      <Route exact path="/" render={(props) => <Landing { ...props } />} />
      <Route path="/login" render={(props) => <Login { ...props } />} />
      <Route path="/register" render={(props) => <Register { ...props } />} />
      <Route path="/explained" component={Explain} />
      <Route path="/contact-us" component={Contact} />
      <Route path="/terms-and-rules" component={Terms} />
      <Route path="/privacy-policy" component={Privacy} />
      <Route path="/the-lovacane-theory" component={Theory} />
      <Route path="/the-lovacane-glossary" component={Glossary} />
      <AuthRoute path="/settings" component={Settings} isAuthenticated={props.isAuthenticated} />
      <AuthRoute path="/profile" component={Profile} isAuthenticated={props.isAuthenticated} />
      <AuthRoute path="/user-gallery" component={UserGallery} isAuthenticated={props.isAuthenticated} />
      <AuthRoute path="/notification" component={Notification} isAuthenticated={props.isAuthenticated} />
      <AuthRoute path="/gallery/:id" component={Gallery} isAuthenticated={props.isAuthenticated} />
	  <AuthRoute path="/view-profile" component={ViewProfile} isAuthenticated={props.isAuthenticated} />
    <AuthRoute path="/user-list" component={UserList} isAuthenticated={props.isAuthenticated} />
	  <AuthRoute path="/user-profile/:id" component={UserProfile} isAuthenticated={props.isAuthenticated} />
      <AuthRoute path="/plans" component={Plans} isAuthenticated={props.isAuthenticated} />
      <AuthRoute path="/filter-members" component={Filter} isAuthenticated={props.isAuthenticated} />
      <AuthRoute path="/jitsi" component={Jitsi} isAuthenticated={props.isAuthenticated} />
      <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
        <AuthRoute path="/chat" component={TestChat} isAuthenticated={props.isAuthenticated} />
		<AuthRoute path="/message/:id" component={Message} isAuthenticated={props.isAuthenticated} />
      </SnackbarProvider>
    </Switch>
  </BrowserRouter>
);

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);
  return {
    isAuthenticated: sm.isAuthenticated,
    isAdmin: sm.isAdmin,
  };
};

export default connect(
  mapStateToProps,
  undefined,
)(Router);
