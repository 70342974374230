import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

Modal.setAppElement('#root');

const WeerdPremiumModal = ({
  open,
  isOpen,
  customStyles,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={(e) => open(e, "weerdPremiumModalOpen")}
      closeTimeoutMS={500}
      style={customStyles}
      className="modal fade show"
    >
      <div className="modal-dialog modal-md modal-dialog-centered" role="document">
        <div className="modal-content onboardingModal">
          <div className="row">
            <div className="col-lg-12 ">
              <h5 className="fw-bold text-center mb-2">Select Your Subscription Plan</h5>
                <ul className="lh-1-6 fs-14">
                <li>-15 more seconds for Weerd display: <span className="color-green">$1.99</span></li>
                <li>-30 more seconds for Weerd display: <span className="color-green">$1.99 (after purchase of +15)</span></li>
                <li>-15 more characters for Weerds: <span className="color-green">$1.99</span></li>
                <li>-30 more characters for Weerds: <span className="color-green">$1.99 (after purchase of +15)</span></li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 my-auto text-center">
              <img className="ee-image" src="/assets/images/ee-image.png" alt="image" />
            </div>
            <DivContainer className="col-lg-6 col-md-6 col-sm-12">
              <form className="subscription-plan options-box">
                <div className="relative">
                  <input type="radio" name="purchaseable_plan" id="15-seconds"  value="+15 Seconds" />
                  <label className="button red w-100 radius border-black playSound" htmlFor="15-seconds"><span>+15 seconds </span>/ $1.99</label>
                  <i className="fa fa-check"></i>
                </div>
                <div className="relative">
                  <input type="radio" name="purchaseable_plan" id="30-seconds" value="+30 Seconds" />
                  <label  className="button red w-100 radius border-black playSound" htmlFor="30-seconds"><span>+30 seconds </span>/ $1.99 <sub>(after purchase of +15)</sub></label>
                  <i className="fa fa-check"></i>
                </div>
                <div className="relative">
                  <input type="radio" name="purchaseable_plan" id="15-characters" value="+15 characters " />
                  <label  className="button red w-100 radius border-black playSound" htmlFor="15-characters"><span>+15 characters </span>/ $1.99</label>
                  <i className="fa fa-check"></i>
                </div>
                <div className="relative">
                  <input type="radio" name="purchaseable_plan" id="30-characters" value="+30 characters" />
                  <label  className="button red w-100 radius border-black playSound" htmlFor="30-characters"><span>+30 characters </span> / $1.99 <sub>(after purchase of +15)</sub></label>
                  <i className="fa fa-check"></i>
                </div>
                <button data-toggle="modal" data-dismiss="modal" data-target="#PurchaseablePaymentMethodModal" type="button" className="button w-100 radius border-black mt-4 ">Purchase</button>
              </form>
            </DivContainer>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default WeerdPremiumModal;

const DivContainer = styled.div`
  .relative {
    margin-top: 1.5rem;
  }
`
