import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';


const AdminSidebar = (props) => {
  return (
    <DivContainer>
      <div className="d-block justify-content-center">
        <Link to="/feeds" className="admin-link">
          <button type="button" className="btn btn-outline-primary tvbtn-outline">Go to Feeds</button>
        </Link>
        <Link to="/admin/users" className="admin-link">
          <button type="button" className="btn btn-outline-primary tvbtn-outline">Users</button>
        </Link>
        <Link to="/admin/videos" className="admin-link">
          <button type="button" className="btn btn-outline-primary tvbtn-outline">Videos</button>
        </Link>
      </div>
    </DivContainer>
  );
}

export default AdminSidebar;

const DivContainer = styled.div`
  padding: 80px;

  @media (max-width: 580px) {
    padding: 20px;
  }

  .tvbtn-outline {
    width: 100%;
    margin-bottom: 2rem;
  }
`
