import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import socketIOClient from "socket.io-client";
import moment from "moment";
import classnames from "classnames";
import commonUtilites from "../Utilities/common";
import { connect } from "react-redux";
import { stateMappings } from "../redux/stateMappings";
import {
  useGetGlobalMessages,
  useSendGlobalMessage,
  useGetConversationMessages,
  useSendConversationMessage,
} from "../Services/chatService";
import { authenticationService } from "../Services/authenticationService";
import environment from "../environments/environment.dev";
import { APIRequest } from "../lib/apiRequest";
import authHeader from "../Utilities/auth-header";
//import { useGetConversations } from "../Services/chatService";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  headerRow: {
    maxHeight: 60,
    zIndex: 5,
  },
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    color: theme.palette.primary.dark,
  },
  messageContainer: {
    height: "100%",
    display: "flex",
    alignContent: "flex-end",
  },
  messagesRow: {
    maxHeight: "calc(100vh - 200px)",
    overflowY: "auto",
  },
  newMessageRow: {
    width: "100%",
    padding: theme.spacing(0, 2, 1),
  },
  messageBubble: {
    padding: 10,
    border: "1px solid white",
    backgroundColor: "#f2f2f2",
    borderRadius: "0 10px 10px 10px",
    marginTop: 8,
    maxWidth: "25em",
  },
  messageBubbleRight: {
    borderRadius: "10px 0 10px 10px",
  },
  inputRow: {
    display: "flex",
    alignItems: "flex-end",
  },
  form: {
    width: "100%",
  },
  avatar: {
    margin: theme.spacing(1, 1.5),
  },
  listItem: {
    display: "flex",
    width: "100%",
  },
  listItemRight: {
    flexDirection: "row-reverse",
  },
}));

const Message = (props) => {
  const [currentUserId] = useState(
    // authenticationService.currentUserValue.userId
    props.currentUser._id
  );
  const [userId, setUserId] = useState(props.match.params);
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [lastMessage, setLastMessage] = useState(null);
  const [info, setInfo] = useState("");

  const getGlobalMessages = useGetGlobalMessages();
  const sendGlobalMessage = useSendGlobalMessage();
  const getConversationMessages = useGetConversationMessages();
  const sendConversationMessage = useSendConversationMessage();
  let chatBottom = useRef(null);

  useEffect(() => {
    reloadMessages();
    scrollToBottom();
  }, [lastMessage]);

  useEffect(() => {
    const socket = socketIOClient(environment.socket_url);
    socket.on("messages", (data) => setLastMessage(data));
  }, []);

  useEffect(() => {
    APIRequest.request({
      method: "GET",
      headers: authHeader(),
      url: `/get-profile/${userId.id}`,
    }).then((result) => {
      setInfo(result.data);
    });
  }, []);

  const reloadMessages = () => {
    getConversationMessages(userId.id).then((res) => setMessages(res));
  };

  const scrollToBottom = () => {
    chatBottom.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSubmit = (e) => {
    e.preventDefault();
    sendConversationMessage(userId.id, newMessage).then((res) => {
      setNewMessage("");
    });
  };

  return (
    <section className="mulitcolor-bg bg-img profile">
      <div className="container chat">
        <div className="row ">
          <div className="col-lg-12 bgcolor-red">
            <h4 className="fs-shrinkhand text-white py-2">{info.username}</h4>
          </div>
        </div>
        <div className="row bgcolor-white">
          <div className="col-lg-12 col-md-12 my-3">
            <div className="chat-history">
              <div className="chat-history">
                <div className="messages">
                  <ul className="chat-list scrollable-grey">
                    {messages.map((m) => (
                      <li
                        key={m._id}
                        className={
                          m.fromObj[0]?._id === currentUserId
                            ? "sender"
                            : "receiver"
                        }
                      >
                        <div className="msg-img">
                          {/* <img src="/assets/images/tom.jpg" alt="" /> */}
                          <Avatar>
                            {commonUtilites.getInitialsFromName(
                              m.fromObj[0]?.username
                            )}
                          </Avatar>
                          <div className="bubble-time text-right">
                            {`${moment(parseInt(m.date)).fromNow()}, ${moment(
                              parseInt(m.date)
                            ).format("h:mm a")} `}
                            <i className="fas fa-check color-green"></i>
                          </div>
                        </div>
                        <div className="msg-bubble">
                          <p>{m.body}</p>
                        </div>
                      </li>
                    ))}
                    <div ref={chatBottom} />
                  </ul>
                </div>
              </div>

              <form
                className="position-relative"
                id="chat-message"
                onSubmit={handleSubmit}
              >
                <input
                  id="message"
                  type="text"
                  className="chat-input"
                  placeholder="Type something..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
                <div className="click-options">
                  <ul>
                    <li>
                      <button type="submit" className="send-btn">
                        <i className="fab fa-telegram-plane"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);
  return {
    currentUser: sm.user,
  };
};

export default connect(mapStateToProps, undefined)(Message);
