import React, {useState} from 'react';
import Button from "@material-ui/core/Button";
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { useTheme } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import environment from '../environments/environment.dev';

const ImageSlider = (props) => {
  const CollectionSize = props.dp.length;
  const theme = useTheme();
  const [index, setActiveStep] = useState(0);
  const goToNextPicture = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const goToPreviousPicture = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
	<>
		<img
		className="w-100 d-block"
		src={environment.img_url+"userImages/"+props.dp[index]}
		alt="profile picture"
		style={{height: "50%"}}
		/>
		<MobileStepper
		variant="text"
		position="static"
		index={index}
		steps={CollectionSize}
		activeStep={index}
		backButton={
			<Button
			size="small"
			onClick={goToPreviousPicture}
			disabled={index === 0}
			>
			<KeyboardArrowLeft />
			Previous
			</Button>
		}
		nextButton={
			<Button
			size="small"
			onClick={goToNextPicture}
			disabled={index === CollectionSize - 1}
			>
			Next
			{theme.direction !== "rtl" ? (
				<KeyboardArrowRight />
			) : (
				<KeyboardArrowLeft />
			)}
			</Button>
		}
		/>
	</>
  );
}

export default ImageSlider;