const environment = {
  //production : false,
  base_url: 'https://lovacane.com/',
  img_url: 'https://lovacane.com/',
  api_url: 'https://lovacane.com/api',

  socket_url: 'https://lovacane.com',

  //base_url: 'http://localhost:3000/',
  //img_url: 'http://localhost:5000/',
  //api_url: "http://localhost:5000/api",

}
export default environment;
