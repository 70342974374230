import React, {
  Component,
  Fragment,
  useState,
  useCallback,
  useEffect,
} from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { stateMappings } from "../redux/stateMappings";
import { signOut } from "../redux/auth/thunks";
import { setWeerdyModal } from "../redux/modal/thunks";
import { APIRequest } from "../lib/apiRequest";
import authHeader from "../Utilities/auth-header";
import { setToast } from "../lib/toast";
import environment from "../environments/environment.dev";

class UserGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bio: "",
      interests: "",
      facts: "",
      info: {},
      imgSrc: [],
      imgCollection: [],
      topPicks: [],
      isEdited: 0,
      physicalGender: "",
      identifiedGender: "",
      letter: "",
      images: [],
      image_html: "",
      show_pop: 0,
      image_src: "",
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const interestedGender = user.physicalGender == "male" ? "female" : "male";
    var { image_html } = this.state;

    APIRequest.request({
      method: "GET",
      headers: authHeader(),
      url: `/get-profile/${user._id}`,
    }).then((result) => {
      this.setState({ info: result.data });
      this.setState({ bio: result.data.bio });
      this.setState({ interests: result.data.interests });
      this.setState({ facts: result.data.facts });
      this.setState({ imgCollection: result.data.imgCollection });
      this.setState({ physicalGender: result.data.physicalGender });
      this.setState({ identifiedGender: result.data.identifiedGender });
      this.setState({ letter: result.data.letter });
    });

    APIRequest.request({
      method: "GET",
      headers: authHeader(),
      url: `/user-images/${user._id}`,
    }).then((result) => {
      this.setState({ images: result.data });
    });

    var date = new Date();
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var maxYear = date.getFullYear() - 1 - user.maxAge;
    var minYear = date.getFullYear() - user.minAge;
    var minYearDate = month + "/" + day + "/" + minYear;
    var maxYearDate = month + "/" + day + "/" + maxYear;

    APIRequest.request({
      method: "POST",
      headers: authHeader(),
      url: `/top-picks`,
      data: {
        interestedGender: interestedGender,
      },
    }).then((result) => {
      this.setState({
        topPicks: result["data"].filter(
          (e) =>
            new Date(e.birthday).getTime() <= new Date(minYearDate).getTime() &&
            new Date(e.birthday).getTime() > new Date(maxYearDate).getTime()
        ),
      });
    });
  }

  Edit = (e) => {
    e.preventDefault();
    this.setState({ isEdited: 1 });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  fileSelectedHandler = (event) => {
    const { images } = this.state;

    var len = event.target.files.length;
    const { settings } = this.props;
    const date = new Date(settings.plan_expires);
    const elapsed = date.getTime(); // Elapsed time in MS
    const current_date = new Date();
    const current_elapsed = current_date.getTime(); // Elapsed time in MS
    if (!elapsed || current_elapsed > elapsed) {
      var total_pic = 5;
    } else {
      var total_pic = 10;
    }

    total_pic = total_pic - images.length;

    if (len <= total_pic) {
      var reader = "";
      this.setState({
        imgSrc: [],
      });
      for (var i = 0; i < len; i++) {
        reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = (e) => {
          this.setState((prevState) => ({
            imgSrc: [...prevState.imgSrc, e.target.result],
          }));
        };
      }
    } else {
      if (total_pic == 0) {
        alert("You have already reached your gallery image limit");
      } else {
        alert("Can not upload more than " + total_pic + " images");
      }
    }
  };

  upload = (event) => {
    setToast("success", "Uploading in Progress! Please Wait.");

    const { imgSrc } = this.state;
    const { user } = this.props;
    APIRequest.request({
      method: "PUT",
      headers: authHeader(),
      url: `/update-images`,
      data: {
        imgCollection: imgSrc,
        user: user._id,
      },
    }).then((result) => {
      setToast("success", "Images are uploaded successfully.");
      window.location.reload(false);
    });
  };

  handleSubmit = (e) => {
    e.preventDefault(e);
    const { bio, interests, facts, physicalGender, identifiedGender, letter } =
      this.state;
    const { user } = this.props;
    this.setState({ isEdited: 0 });
    APIRequest.request({
      method: "PUT",
      headers: authHeader(),
      url: `/update-profile`,
      data: {
        bio: bio,
        interests: interests,
        facts: facts,
        user: user._id,
        physicalGender: physicalGender,
        identifiedGender: identifiedGender,
        letter: letter,
      },
    }).then((result) => {
      setToast("success", "Profile has been updated successfully.");
      window.location.reload(false);
    });
  };

  deletePicture = (event) => {
    if (!window.confirm("Do you want to delete the image?")) {
      return false;
    }

    const { id, value } = event.target;

    APIRequest.request({
      method: "GET",
      headers: authHeader(),
      url: `/delete-user-image/${id}`,
    }).then((result) => {
      setToast("success", "Gallery Image has been deleted successfully.");
      window.location.reload(false);
    });
  };

  showPop = (event) => {
    var src = event.target.attributes.getNamedItem("src").value;
    this.setState({
      image_src: src,
    });

    this.setState({
      show_pop: 1,
    });
  };

  hidePop = (event) => {
    this.setState({
      image_src: "",
    });

    this.setState({
      show_pop: 0,
    });
  };

  render() {
    const { signOut, setWeerdyModal } = this.props;
    const { username, email, plan, _id } = this.props.user || {};
    const {
      isEdited,
      bio,
      interests,
      facts,
      info,
      imgCollection,
      topPicks,
      images,
      physicalGender,
      identifiedGender,
      letter,
      image_html,
      image_src,
      show_pop,
    } = this.state;

    const { settings } = this.props;
    const date = new Date(settings.plan_expires);
    const elapsed = date.getTime(); // Elapsed time in MS
    const current_date = new Date();
    const current_elapsed = current_date.getTime(); // Elapsed time in MS
    if (!elapsed || current_elapsed > elapsed) {
      var total_pic = 5;
    } else {
      var total_pic = 10;
    }

    if (show_pop == 1) {
      var pop_display = "block";
    } else {
      var pop_display = "none";
    }

    return (
      <Fragment>
        <section className="bg-img profile">
          <div className="container">
            <div className="row content">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="position-relative bg-white">
                  <div className="top-detail justify-content-center flex-wrap">
                    <div
                      id="myModal"
                      className="modal"
                      style={{ display: pop_display }}
                      onClick={this.hidePop}
                    >
                      <img
                        className="modal-content"
                        id="img01"
                        src={image_src}
                      />
                      <div id="caption"></div>
                    </div>
                    <h2 class="fs-shrinkhand">Photo Gallery</h2>  <div style={{float:"right", 'margin-left':"60%"}}><a href="/profile">Back to Profile</a></div>
                    <div className="w-100 text-center">
                      <label htmlFor="file-input" style={{ cursor: "pointer" }}>
                        Upload Images:
                      </label>
                      &nbsp;&nbsp;
                      <input
                        id="file-input"
                        type="file"
                        accept="image/*"
                        onChange={this.fileSelectedHandler}
                        multiple
                      />
                    </div>
                  </div>

                  <div className="row top-picks gap-y-1">
                    {images.map((info, index) => (
                      <>
                        {_id != info._id ? (
                          <div
                            className="col-lg-3 col-md-3 col-sm-6 text-center"
                            key={index}
                          >
                            <div className="position-relative h-100">
                              <a className="lighty ">
                                {info.picture ? (
                                  <img
                                    onClick={this.showPop}
                                    className="myImg"
                                    src={
                                      environment.img_url +
                                      "userImages/gallery/" +
                                      info.picture
                                    }
                                    alt="profile"
                                  />
                                ) : (
                                  <img
                                    src="./assets/images/img.png"
                                    alt="profile"
                                  />
                                )}
                                <h5>
                                  <a
                                    href="javascript:void(0);"
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={this.deletePicture}
                                    id={info._id}
                                  >
                                    Delete
                                  </a>
                                </h5>
                              </a>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </div>

                  <div className="bottom-detail">
                    {/*{this.state.imgSrc.length>0 ? '' : imgCollection.map((src,index) => <img src={environment.img_url+"userImages/"+src} key={index} style={{height: "20%", width: "20%"}} />)}*/}
                    {this.state.imgSrc.map((src, index) => (
                      <img
                        src={src}
                        key={index}
                        style={{ height: "20%", width: "20%" }}
                      />
                    ))}
                    {this.state.imgSrc.length > 0 ? (
                      <div className="text-center">
                        <a
                          href="#"
                          onClick={this.upload}
                          className="button red rounded fw-bold"
                        >
                          Upload
                        </a>
                      </div>
                    ) : (
                      <div className="text-center"></div>
                    )}
                                    <div className="text-right" style={{"display":"none"}}>
                                        <a href="" className="shrinkhand-link">
                                            <i className="fa fa-long-arrow-left"></i> Back to <b>Mike's</b> profile
                                        </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    user: sm.user,
    settings: sm.settings,
  };
};

const mapActionsToProps = {
  signOut,
  setWeerdyModal,
};

export default connect(mapStateToProps, mapActionsToProps)(UserGallery);

const SectionContainer = styled.section`
  .plan {
    text-transform: capitalize;

    span {
      cursor: pointer;

      &:hover {
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        color: inherit;
      }
    }
  }
`;
