import React, { Fragment } from 'react';


const Explain = () =>
{
  return (
    <Fragment>
      <section>
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center">
              <h2 className="fs-shrinkhand">The Lovacane Glossary</h2>
              <img className="my-4 w-100" src="/assets/images/heart-design.png" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="pt-0">
          <div className="container">
              <div className="row ">
                <div className="col-lg-12 col-sm-12 mx-auto">

                  <div className="legal-content">
                      <div className="scrollable">
                        <ul>
                          <li>- Lovacane – A website where LGBTQ men have the option to date LGBTQ women.</li>
                          <li>- Physical-Gender – A user’s gender at birth.</li>
                          <li>- LGBTQ – Lesbian: A woman who is romantically, physically, or sexually attracted to females; Gay: A term used to refer to all people, regardless of sex, who are primarily romantically, physically, or sexually attracted to the same sex; Bisexual: A term used to describe someone who is romantically, physically, or sexually attracted to two genders; Transgender (TM/TW): A term meant to describe an individual whose gender identity does not match their physical-gender; Queer and Questioning: The process of examining one’s sexual orientation and/or gender identity.</li>
                          <li>- TW – Transgendered Woman: A term meant to describe a man whose gender identity does not match his physical-gender.</li>
                          <li>- TM – Transgendered Man: A term meant to describe a woman whose gender identity does not match her physical-gender.</li>
                          <li>- User-identifiers – User-specific identifiers that allow users to know a little about the person they are viewing.</li>
                          <li>- User-identifying selections – The selections or options that accompany user-identifiers.</li>
                          <li>- Go Hush – An option that allows (paying) exclusive users to hide their profiles.</li>
                          <li>- Hush Heart – A heart that looks like the heart in the Lovacane logo and functions as a secret admirer token. Only exclusive users get access to Hush hearts and may send up to three per day.</li>
                        </ul>
                      </div>
                  </div>
                </div>
              </div>
          </div>
      </section>
    </Fragment>
  );
}

export default Explain;

