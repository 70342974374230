import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { stateMappings } from '../redux/stateMappings';
import { signOut } from '../redux/auth/thunks';
import { setWeerdyModal } from '../redux/modal/thunks';
import { APIRequest } from '../lib/apiRequest';
import authHeader from '../Utilities/auth-header';
import { setToast } from '../lib/toast';
import environment from '../environments/environment.dev';
import moment from "moment";

class Notification extends Component {
  constructor(props) {
    super(props);
	
    this.state = {
		bio: "",
		interests: "",
		facts: "",
		info: {},
		imgSrc: [],
		imgCollection: [],
		topPicks: [],
		isEdited: 0,
		physicalGender: "",
		identifiedGender: "",
		letter: "",
		notifications: [],
		userId: this.props.match.params
    };
  }
   
	componentDidMount() {
		const {user} = this.props;
		const interestedGender = user.physicalGender == 'male' ? 'female' : 'male';
		const {userId} = this.state;

		APIRequest.request({
			method: 'GET',
			headers: authHeader(),
			url: `/get-profile/${user._id}`
		 }).then(result => {
			 this.setState({info: result.data});
			 this.setState({bio: result.data.bio});
			 this.setState({interests: result.data.interests});
			 this.setState({facts: result.data.facts});
			 this.setState({imgCollection: result.data.imgCollection});
			 this.setState({physicalGender: result.data.physicalGender});
			 this.setState({identifiedGender: result.data.identifiedGender});
			 this.setState({letter: result.data.letter});
		 });

		//629752139520aa15304619a0   ${user._id}
		 APIRequest.request({
			method: 'GET',
			headers: authHeader(),
			url: `/notifications/${user._id}`
		 }).then(result => {
			 this.setState({notifications: result.data});
		 });
		 



	}







  render() {
    const { signOut, setWeerdyModal } = this.props;
    const { username, email, plan, _id } = this.props.user || {};
	const { isEdited,bio,interests,facts,info,imgCollection,topPicks,notifications,physicalGender,identifiedGender,letter } = this.state;
	
	const {settings} = this.props;
	const date = new Date(settings.plan_expires);
	const elapsed = date.getTime(); // Elapsed time in MS
	const current_date = new Date();
	const current_elapsed = current_date.getTime(); // Elapsed time in MS
	if(!elapsed || current_elapsed > elapsed) {
		var total_pic = 5;
	}else {
		var total_pic = 10;
	}
  console.log(notifications);
    return (
      <Fragment>
        <section className="bg-img profile">
          <div className="container">
              <div className="row content">

                <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="position-relative bg-white">
			   		
                  <div className="top-detail">
                  <h2 class="fs-shrinkhand">Notifications</h2>
                  </div>

     
                </div>

                <div className="row">
		       <table>
				{notifications.map((info, index) => 
				<>
			
                 <tr>
					<td>
					&nbsp;&nbsp;<span dangerouslySetInnerHTML={{ __html: info.message }} /> - {moment(info.createdAt).format('DD/MM/YYYY')} 
					</td>
			
				 </tr>
                  <tr><td></td></tr>            

				</>
                  )}
                </table>
                </div>


                </div>
    
              </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);

  return {
    user: sm.user,
	settings: sm.settings,
  };
};

const mapActionsToProps = {
  signOut,
  setWeerdyModal,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(Notification);

const SectionContainer = styled.section`
  .plan {
    text-transform: capitalize;

    span {
      cursor: pointer;

      &:hover {
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        color: inherit;
      }
    }
  }
`


