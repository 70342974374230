import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';

import { stateMappings } from '../redux/stateMappings';
import { signIn } from '../redux/auth/thunks';
import { setToast } from '../lib/toast';

Modal.setAppElement('#root');

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    this.props.history.push("/profile");
  }

  onChange = ({ target: { name, value } }) =>
    this.setState(state => ({
      ...state,
      [name]: value,
    }));

  handleSubmit = async (e) => {
    e.preventDefault();

    const { email, password } = this.state;

    try {
      await this.props.signIn({
        email: email.toLowerCase(),
        password,
      });

      this.props.history.push('/profile');
    } catch (error) {
      return;
    }

  };

  render() {
    const {
      open,
      isOpen,
      customStyles,
      isAuthenticated,
    } = this.props;
    const { email, password } = this.state;

    return (
      <section >
        <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-6 col-sm-12 mx-auto text-center bgcolor-lt-gray p-8 radius-md">
                <h2 className="fs-georgia-regular mt-4">Login</h2>
                <img className="my-8" src="/assets/images/login-icon.png" alt="" />
                <div className="contact-form p-0">
                  <form onSubmit={this.handleSubmit}>
                    <div className="row login" style={{ margin: "0 auto" }}>
                      <div className="col-lg-12 col-md-12 col-sm-12 my-2">
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          onChange={this.onChange}
                          required
                        />
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 my-2">
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          value={password}
                          onChange={this.onChange}
                          required
                        />
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 my-2">
                        <button className="button red d-block w-100" type="submit">Login</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
  };
};

const mapActionsToProps = {
  signIn
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(Login);
