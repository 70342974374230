import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import Slider from 'rc-slider';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import { stateMappings } from '../redux/stateMappings';
import { userRemove, signOut, userEdit } from '../redux/auth/thunks';
import { setWeerdyModal } from '../redux/modal/thunks';
import 'rc-slider/assets/index.css';
import { setToast } from '../lib/toast';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const TooltipSlider = createSliderWithTooltip(Slider)
const Range = createSliderWithTooltip(Slider.Range);

const myComponentStyle = {
  display: 'none',
}


class Settings extends Component {
  constructor(props) {
    super(props);

    var latitude = this.getCookie("lova_lat_cookie");
    var longitude = this.getCookie("lova_long_cookie");
    //alert(longitude+" "+latitude);
    this.state = {
      identifiedGender: '',
      physicalGender: '',
      letter: '',
	  state: '',
	  postcode: '',
    lat:latitude,
    long:longitude, 
    latitude:latitude,
    longitude:longitude,  
    };
  }


  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }


  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.settings, this.props.settings)) {
      this.setState({
        ...this.state,
        ...this.props.settings
      });
    }
  }

  onChange = ({ target: { name, value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    }, () => {
      this.props.updateUser(this.state);
    });
  }
  
  onChange2 = ({ target: { name, value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    }, () => {
      // this.props.updateUser(this.state);
    });
  }

  onCheckBoxChange = ({ target: { name, checked } }) => {
    this.setState({
      ...this.state,
      [name]: checked
    }, () => {
      this.props.updateUser(this.state);
    });
  }

  onHandleDistanceChange = (value, isUpdate = false) => {
    this.setState({
      ...this.state,
      distance: value
    }, () => {
      if (!isUpdate) {
        return;
      }
      //this.props.updateUser(this.state);
    });
  }

  onHandleAgeChange = (value, isUpdate = false) => {
    this.setState({
      ...this.state,
      minAge: value[0],
      maxAge: value[1],
    }, () => {
      if (!isUpdate) {
        return;
      }
      //this.props.updateUser(this.state);
    });
  }

  handleChange = address => {
	// this.setState({ location: address });
  };

  handleSelect = address => {
    this.setState({
      ...this.state,
      location: address,
    }, () => {
      this.props.updateUser(this.state);
      geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error));
    });
  };
  
  handleSubmit = (event) => {
	  event.preventDefault();

    var latitude1 = this.getCookie("lova_lat_cookie");
    var longitude1 = this.getCookie("lova_long_cookie");

    this.setState({ lat: latitude1 });
    this.setState({ long: longitude1 });

    this.setState({ latitude: latitude1 });
    this.setState({ longitude: longitude1 });

    var arr = this.state;

    arr.lat = latitude1;
    arr.long = longitude1;

	  const {state,postcode,latitude,longitude, lat, long} = this.state;
    //alert(latitude+"  "+longitude);return;
	  this.props.updateUser(arr);
	  setToast("success", "Successfully Updated!");
  }

  render() {
    const { setModal, deleteUser, currentUser } = this.props;
    const { username, plan } = currentUser || {};
    const {
      identifiedGender,
      physicalGender,
      letter,
	  state,
	  postcode
    } = this.state;

    return (
      <Fragment>
        <section>
          <div className="container">
            <div className="row ">
              <div className="col-lg-12 text-center">
                <h2 className="fs-shrinkhand">Settings</h2>
              </div>
            </div>
          </div>
        </section>
        <div className="border-bottom-red"></div>
        <section className="p-0">
            <div className="full-width-container">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 mx-auto text-center border-white radius-md p-8 sm:p-4">
                      <div className="login mt-16 sm:mt-8">
					  <form onSubmit={this.handleSubmit}>
                        <div className="filters">
                          <h4 className="mb-8 fw-bold fs-georgia-regular text-left">Control Your Profile</h4>
                          <div className="list">
                            <p>Your Location</p>
							<select className="form-control" id="state1" name="state" value={state} onChange={this.onChange2}>
								<option value="">Select State</option>
                <option value="Alabama">Alabama</option>
	<option value="Alaska">Alaska</option>
	<option value="Arizona">Arizona</option>
	<option value="Arkansas">Arkansas</option>
	<option value="California">California</option>
	<option value="Colorado">Colorado</option>
	<option value="Connecticut">Connecticut</option>
	<option value="DDelawareE">Delaware</option>
	<option value="District Of Columbia">District Of Columbia</option>
	<option value="Florida">Florida</option>
	<option value="Georgia">Georgia</option>
	<option value="Hawaii">Hawaii</option>
	<option value="Idaho">Idaho</option>
	<option value="Illinois">Illinois</option>
	<option value="Indiana">Indiana</option>
	<option value="Iowa">Iowa</option>
	<option value="Kansas">Kansas</option>
	<option value="Kentucky">Kentucky</option>
	<option value="Louisiana">Louisiana</option>
	<option value="Maine">Maine</option>
	<option value="Maryland">Maryland</option>
	<option value="Massachusetts">Massachusetts</option>
	<option value="Michigan">Michigan</option>
	<option value="Minnesota">Minnesota</option>
	<option value="Mississippi">Mississippi</option>
	<option value="Missouri">Missouri</option>
	<option value="Montana">Montana</option>
	<option value="Nebraska">Nebraska</option>
	<option value="Nevada">Nevada</option>
	<option value="New Hampshire">New Hampshire</option>
	<option value="New Jersey">New Jersey</option>
	<option value="New Mexico">New Mexico</option>
	<option value="New York">New York</option>
	<option value="North Carolina">North Carolina</option>
	<option value="North Dakota">North Dakota</option>
	<option value="Ohio">Ohio</option>
	<option value="Oklahoma">Oklahoma</option>
	<option value="Oregon">Oregon</option>
	<option value="Pennsylvania">Pennsylvania</option>
	<option value="Rhode Island">Rhode Island</option>
	<option value="South Carolina">South Carolina</option>
	<option value="South Dakota">South Dakota</option>
	<option value="Tennessee">Tennessee</option>
	<option value="Texas">Texas</option>
	<option value="Utah">Utah</option>
	<option value="Vermont">Vermont</option>
	<option value="Virginia">Virginia</option>
	<option value="Washington">Washington</option>
	<option value="West Virginia">West Virginia</option>
	<option value="Wisconsin">Wisconsin</option>
	<option value="Wyoming">Wyoming</option>
							</select>
                          </div>
						  <div className="list">
                            <p>Your Zipcode</p>
							<input className="form-control" type="text" id="postcode1" name="postcode" value={postcode} onChange={this.onChange2} placeholder="Enter Zipcode"/>
						  </div>
                          <div className="list" style={myComponentStyle}>
                            <p>Match Distance</p>
                            <TooltipSlider
                              max={5000}
                              value={this.state?.distance || 0}
                              tipFormatter={value => `${value} mile(s)`}
                              onAfterChange={(value) => this.onHandleDistanceChange(value, true)}
                              onChange={(value) => this.onHandleDistanceChange(value, false)}
                            />
                          </div>
						  
                          <div className="list" style={myComponentStyle}>
                            <p>Match Age</p>
                            <Range
                              min={18}
                              max={70}
                              value={[this.state?.minAge || 18, this.state?.maxAge || 70]}
                              tipFormatter={value => `${value}`}
                              onAfterChange={(value) => this.onHandleAgeChange(value, true)}
                              onChange={(value) => this.onHandleAgeChange(value, false)}
                            />
                          </div>
						  
                          <div className="list" style={myComponentStyle}>
                            <p>My Physical-Gender</p>
                            <select className="settings-select form-control" value={physicalGender} name="physicalGender" onChange={this.onChange2}>
                              <option value=""></option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </div>
                          <div className="list" style={myComponentStyle}>
                            <p>I Identify As</p>
                            <select className="settings-select form-control" value={identifiedGender} name="identifiedGender" onChange={this.onChange2}>
                              <option value=""></option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </div>
                          <div className="list" style={myComponentStyle}>
                            <p>My LGBTQ Letter</p>
                            <select className="settings-select form-control" value={letter} name="letter" onChange={this.onChange2}>
                              <option value=""></option>
                              <option value="L">(L)esbian</option>
                          <option value="G">(G)ay</option>
                          <option value="B">(B)isexual</option>
                          <option value="TW">(T)ransexual Woman</option>
                          <option value="TM">(T)ransexual Man</option>
						  <option value="Q">(Q)ueer</option>
                            </select>
                          </div>
                        </div>
                        <div id="checking" style={{color:"green",display:"none"}}>Wait, Checking location data...<br/></div> 
						<input className="button red" type="submit" value="Update" id="save_location" /> 
            {/*
						<div className="filters">
                          <div className="list">
                            <p>Don’t Show My Age</p>
                            <label className="switch-wrap">
                              <input type="checkbox" name="disableAge" checked={this.state?.disableAge || false} onChange={this.onCheckBoxChange} />
                              <div className="switch"></div>
                            </label>
                          </div>
                          <div className="list">
                            <p>Don’t Show My Distance</p>
                            <label className="switch-wrap">
                              <input type="checkbox" name="disableDistance" checked={this.state?.disableDistance || false} onChange={this.onCheckBoxChange} />
                              <div className="switch"></div>
                            </label>
                          </div>
                        </div>*/}
						</form>
                        {/*<div className="filters">
                          <h4 className="mb-8 fw-bold fs-georgia-regular text-left">Control Your Profile</h4>
                          <div className="list">
                            <p>Don’t Show My age</p>
                            <label className="switch-wrap">
                              <input type="checkbox" name="disableAge" checked={this.state?.disableAge || false} onChange={this.onCheckBoxChange} />
                              <div className="switch"></div>
                            </label>
                          </div>
                          <div className="list">
                            <p>Don’t Show My Distance</p>
                            <label className="switch-wrap">
                              <input type="checkbox" name="disableDistance" checked={this.state?.disableDistance || false} onChange={this.onCheckBoxChange} />
                              <div className="switch"></div>
                            </label>
                          </div>
                        </div>*/}
						{/*
                        <div className="filters">
                          <h4 className="my-8 fs-georgia-regular text-left">Notifications</h4>
                          <div className="list">
                            <p>Push Notifications</p>
                            <p><a href="#"><i className="fas fa-chevron-right color-black"></i></a></p>
                          </div>
                          <div className="list">
                            <p>Advanced Notifications</p>
                            <p><a href="#"><i className="fas fa-chevron-right color-black"></i></a></p>
                          </div>
                        </div>
						*/}
                        {/* <div className="filters">
                          <h4 className="my-8 fs-georgia-regular text-left">Payment Account</h4>
                          <div className="list">
                            <p>Manage Payment Method</p>
                            <p><a href="#"><i className="fas fa-chevron-right color-black"></i></a></p>
                          </div>
                        </div> */}
                        <div className="filters">
                          <h4 className="my-8 fw-bold fs-georgia-regular text-left">Lovacane Explained</h4>
                          <div className="list">
                            <p><a href="/the-lovacane-theory" className="color-black">The Lovacane Theory</a></p>
                            <div><a href="/the-lovacane-theory"><i className="fas fa-chevron-right color-black"></i></a></div>
                          </div>
                          <div className="list">
                            <p><a href="/the-lovacane-glossary" className="color-black">The Lovacane Glossary</a></p>
                            <div><a href="/the-lovacane-glossary"><i className="fas fa-chevron-right color-black"></i></a></div>
                          </div>
                        </div>
                        <div className="filters">
                          <h4 className="my-8 fw-bold fs-georgia-regular text-left">Legal</h4>
                          <div className="list">
                            <p><a href="/terms-and-rules" className="color-black">Terms and Rules</a></p>
                            <div><a href="/terms-and-rules"><i className="fas fa-chevron-right color-black"></i></a></div>
                          </div>
                          <div className="list">
                            <p><a href="/privacy-policy" className="color-black">Privacy Policy</a></p>
                            <div><a href="/privacy-policy"><i className="fas fa-chevron-right color-black"></i></a></div>
                          </div>
                        </div>
                        <button className="button red d-block w-100 mt-8" onClick={this.props.signOut}>Logout</button>
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="text-center setting-images">
                      <img className="w-100" src="/assets/images/settings-right.png" alt="" />
                      <img className="w-100 my-8 sm:my-4 d-none" src="/assets/images/setting-2.png" alt="" />
                      <img className="w-100 my-8 sm:my-4 d-none" src="/assets/images/setting-3.png" alt="" />
                    </div>
                  </div>
                </div>
            </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);

  return {
    settings: sm.settings,
    currentUser: sm.user,
  };
};

const mapActionsToProps = {
  setModal: setWeerdyModal,
  deleteUser: userRemove,
  updateUser: userEdit,
  signOut,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(Settings);

const SectionContainer = styled.section`
  .plan {
    text-transform: capitalize;
  }
`


