import {
  setModal,
} from './actions';


export const setWeerdyModal = (modal) => async (
  dispatch,
) => {
  console.log('modal open', modal);
  dispatch(setModal(modal));
};

