import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import LanguageIcon from "@material-ui/icons/Language";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import socketIOClient from "socket.io-client";
import moment from "moment";

import { useGetConversations } from "../../Services/chatService";
import { authenticationService } from "../../Services/authenticationService";
import commonUtilites from "../../Utilities/common";
import environment from "../../environments/environment.dev";

const useStyles = makeStyles((theme) => ({
  subheader: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  globe: {
    height: 68,
    width: 68
  },
  subheaderText: {
    color: theme.palette.primary.dark,
  },
  list: {
    maxHeight: "calc(100vh - 183px)",
    overflowY: "auto",
  },
}));

const Conversations = (props) => {
  const classes = useStyles();
  const [conversations, setConversations] = useState([]);
  const [newConversation, setNewConversation] = useState(null);
  const getConversations = useGetConversations();

  // Returns the recipient name that does not
  // belong to the current user.
  const handleRecipient = (recipients) => {
    for (let i = 0; i < recipients.length; i++) {
      if (
        recipients[i].email !==
        props.currentUser?.email
      ) {
        return recipients[i];
      }
    }
    return null;
  };

  useEffect(() => {
    getConversations().then((res) => setConversations(res));
  }, [newConversation]);

  useEffect(() => {
    let socket = socketIOClient(environment.socket_url);
    socket.on("messages", (data) => setNewConversation(data));

    return () => {
      socket.removeListener("messages");
    };
  }, []);

  return (
    // <List className={classes.list}>
    //   <ListItem
    //     classes={{ root: classes.subheader }}
    //     onClick={() => {
    //       props.setScope("Global Chat");
    //     }}
    //   >
    //     <ListItemAvatar>
    //       <Avatar className={classes.globe}>
    //         <LanguageIcon />
    //       </Avatar>
    //     </ListItemAvatar>
    //     <ListItemText className={classes.subheaderText} primary="Global Chat" />
    //   </ListItem>
    //   <Divider />

    //   {conversations && (
    //     <React.Fragment>
    //       {conversations.map((c) => (
    //         <ListItem
    //           className={classes.listItem}
    //           key={c._id}
    //           button
    //           onClick={() => {
    //             props.setUser(handleRecipient(c.recipientObj));
    //             props.setScope(handleRecipient(c.recipientObj)?.username);
    //           }}
    //         >
    //           <ListItemAvatar>
    //             <Avatar>
    //               {commonUtilites.getInitialsFromName(
    //                 handleRecipient(c.recipientObj)?.username
    //               )}
    //             </Avatar>
    //           </ListItemAvatar>
    //           <ListItemText
    //             primary={handleRecipient(c.recipientObj)?.username}
    //             secondary={<React.Fragment>{c.lastMessage}</React.Fragment>}
    //           />
    //         </ListItem>
    //       ))}
    //     </React.Fragment>
    //   )}
    // </List>
    <ul className="people-list scrollable-grey">
	{/*<li
        className={`user align-items-center${props.scope === "Global Chat" ? " active" : ""}`}
        onClick={() => {
          props.setScope("Global Chat");
        }}
      >
        <div className="uer-profile position-relative">
          <Avatar className={classes.globe}>
            <LanguageIcon />
          </Avatar>
          <span className="status-icon green"></span>
        </div>
        <div className="personal-details">
          <h6>
            Global Chat
          </h6>
          
        </div>
        <div className="chat-status">
          
        </div>
	</li>*/}

      {(conversations || []).map((c) => (
        <li
          key={c._id}
          className={`user align-items-center${props.scope === handleRecipient(c.recipientObj)?.username ? " active" : ""}`}
          onClick={() => {
            props.setUser(handleRecipient(c.recipientObj));
            props.setScope(handleRecipient(c.recipientObj)?.username);
          }}
        >
          <div className="uer-profile position-relative">
            <Avatar style={{ height: '40px', width: '40px' }}>
              {commonUtilites.getInitialsFromName(
                handleRecipient(c.recipientObj)?.username
              )}
            </Avatar>
            {/* <img src="/assets/images/profile-main.jpg" alt="profile" /> */}
            <span className="status-icon green"></span>
          </div>
          <div className="personal-details">
            <h6>
              <a href={"https://lovacane.com/user-profile/"+handleRecipient(c.recipientObj)?._id}>{handleRecipient(c.recipientObj)?.username}</a>
            </h6>
            <p className="fs-poppins">{c.lastMessage}</p>
          </div>
          <div className="chat-status">
            <p className="chat-time">{moment(parseInt(c.date)).fromNow()}</p>
          </div>
        </li>
      ))}
    </ul>

  );
};

export default Conversations;
