import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';

import { stateMappings } from '../redux/stateMappings';
import { signIn } from '../redux/auth/thunks';
import { setToast } from '../lib/toast';

Modal.setAppElement('#root');

class LoginModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };
  }

  onChange = ({ target: { name, value } }) =>
    this.setState(state => ({
      ...state,
      [name]: value,
    }));

  handleSubmit = (e) => {
    e.preventDefault();

    const { email, password } = this.state;
    this.props.signIn({
      email: email.toLowerCase(),
      password,
    });
  };

  render() {
    const {
      open,
      isOpen,
      customStyles,
      isAuthenticated,
    } = this.props;
    const { email, password } = this.state;

    return (
      <Modal
        isOpen={isAuthenticated ? false : isOpen}
        onRequestClose={open}
        closeTimeoutMS={500}
        style={customStyles}
        className="modal fade show"
      >
        <div className="modal-dialog modal-md modal-dialog-centered" role="document">
          <div className="modal-content onboardingModal">
            <div className="row">
                <div className="col-lg-6 col-md-6  my-auto text-center">
                  <img className="ee-image" src="/assets/images/ee-image.png" alt="login-ee" />
                </div>
                <div className="col-lg-6 col-md-6 ">
                  <form className="login-form" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label className="input-label" htmlFor="name">Name</label>
                      <input type="text" className="form-control radius border-black fs-poppins" name="email" value={email} onChange={this.onChange} required />
                      <span className="form-control-feedback fa fa-check"></span>
                    </div>
                    <div className="form-group">
                      <label className="input-label" htmlFor="password">Password</label>
                      <input type="password" className="form-control radius border-black fs-poppins"  name="password" value={password} onChange={this.onChange} required />
                      <span className="form-control-feedback fa fa-check"></span>
                    </div>
                    <button type="submit" className="button w-100 radius border-black">Login</button>
                </form>
                </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
  };
};

const mapActionsToProps = {
  signIn
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(LoginModal);
